<script setup>
/* eslint-disable */
import {
  VueFlow,
  useVueFlow,
  Controls,
  Background,
  MiniMap,
  updateEdge,
  Position,
} from '@braks/vue-flow';
import { onMounted, computed, ref, markRaw, nextTick, watch } from 'vue';

import useStore from '../Vueflow/store.js';
import AdditionalControls from '../Vueflow/AdditionalControls.vue';
import QuestionNode from '../Vueflow/QuestionNode.vue';
import AnswerNode from '../Vueflow/AnswerNode.vue';
import EndSessionNode from '../Vueflow/EndSessionNode.vue';
import StartSessionNode from '../Vueflow/StartSessionNode.vue';
import QuestionBar from '../Vueflow/QuestionBar.vue';
import TreeBar from '../Vueflow/TreeBar.vue';
import InterventionBar from '../Vueflow/InterventionBar.vue';
import OpenEndedNode from '../Vueflow/OpenEndedNode.vue';
import InterventionNode from "../Vueflow/InterventionNode.vue";
import CommentNode from '../Vueflow/CommentNode.vue';
import MessageNode from '../Vueflow/MessageNode.vue';
import TreeLinkNode from "../Vueflow/TreeLinkNode.vue";
import eventBus from '../../event-bus.js';
import FlowLoader from '../Vueflow/VueFlowLoader.vue';
import QuestionEditor from '../Vueflow/QuestionEditor.vue';
import KeywordLibrary from '../Vueflow/KeywordsLibrary.vue';
import IfConditionNode from '../Vueflow/IfConditionNode.vue';
import ResourceNode from '../Vueflow/ResourceNode.vue';
import ConditionalSaveStatus from '../Vueflow/ConditionalSaveStatus.vue';
import { onBeforeRouteLeave } from "vue-router";
import PostService from "../../services/PostService";
import QuestionLinkNode from "../Vueflow/QuestionLinkNode.vue";

const savestatus = ref(true);
const emit = defineEmits(['savestatus']);
const dropYPos = ref(100);
const dropXPos = ref(100);


const duplicateMenu = computed(() => ({
      top: dropYPos + 'px;', 
      left: dropXPos + 'px;',
}));

watch(() => savestatus.value, 
  (currentStatus) => {
    emit('savestatus', currentStatus);
  },
  {deep: true}
);

let id = 0;
const getId = () => `dndnode_${id++}`;

const elements = ref([]);

const nodeTypes = {
  questionnode: markRaw(QuestionNode),
  answernode: markRaw(AnswerNode),
  endsessionnode: markRaw(EndSessionNode),
  startsessionnode: markRaw(StartSessionNode),
  opennode: markRaw(OpenEndedNode),
  interventionnode: markRaw(InterventionNode),
  treelinknode: markRaw(TreeLinkNode),
  commentnode: markRaw(CommentNode),
  messagenode: markRaw(MessageNode),
  ifconditionnode: markRaw(IfConditionNode),
  questionlinknode: markRaw(QuestionLinkNode),
  resourcenode: markRaw(ResourceNode),
};

const store = useStore();

const toggleDupMenu = ref(false);


const {
  onConnect,
  addEdges,
  getNode,
  nodes,
  addNodes,
  project,
  viewport,
  edges,
  onPaneReady,
  instance,
  fitView ,
  onEdgesChange, 
  onNodesChange,

} = useVueFlow({
  selectionKeyCode: 'Shift'
});

onEdgesChange((e) => {
  if(savestatus){
    savestatus.value = false;
  }
})



onNodesChange(async (changes) => {
  if(savestatus){
    savestatus.value = false;
  }

   const removalChanges = changes.filter(change => change.type === 'remove');

   //console.log('removals');
   //console.log(removalChanges); 

   removalChanges.forEach(async (element) => {
    console.log(element);
    
    if(element["id"].startsWith("Comment-Node-")){
      let nodeId =  element["id"].slice(13);// extractAfter(element["id"],"Comment-Node-")
      console.log('Comment node was deleted with id: ' + nodeId );
      store.commentsToRemove.push(nodeId)
      
    }
    if(element["id"].startsWith("Message-Node-")){
      let nodeId = element["id"].slice(13);
      console.log('Message node was deleted with id: ' + nodeId);
      store.messagesToRemove.push(nodeId)
      
    }
    if(element["id"].startsWith("Resource-Node-")){
      let nodeId = element["id"].slice(13);
      console.log('Resource node was deleted with id: ' + nodeId);
      store.resourcesToRemove.push(nodeId)
      
    }
    

    if(element["id"].startsWith("question_node-dup-")){
      const idSplit = element["id"].split("-");

      // get a new array removing the id of the duplicate to remove
      let newArray = duplicateDataMap.get("question_node-" + idSplit[3] ).get("duplicates").filter(function(f) {console.log("f is: " + f + " id split is: " + idSplit[2]); return f != idSplit[2] })

      //replaces existing array list with updated array, removing duplicate from it.
      duplicateDataMap.get("question_node-" + idSplit[3]).set("duplicates", newArray);

    }
    if(element["id"].startsWith("question_node-duplink-")){
      const idSplit = element["id"].split("-");
      // get a new array removing the id of the duplicate to remove
      let newArray = duplicateDataMap.get("question_node-" + idSplit[3] ).get("links").filter(function(f) {console.log("f is: " + f + " id split is: " + idSplit[2]); return f != idSplit[2] })

      //replaces existing array list with updated array, removing duplicate from it.
      duplicateDataMap.get("question_node-" + idSplit[3]).set("links", newArray);

    }

   })

   // do some logic

   //applyNodeChanges(changes)
});


//onConnect((params) => addEdges([params]));

onConnect((params) => {
  addEdges([
    {
      ...params,
      updatable: true,
    },
  ]);
});

fitView();
/*
onMounted(() => {
  elements.value = [
    {
      id: '1',
      type: 'startsessionnode',
      label: 'Node 1',
      position: { x: 250, y: 5 },
      class: 'light',
    },
  ];
});
*/

const onDragOver = (event) => {
  console.log('dragging node');
  event.preventDefault();
  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move';
  }
};



//const position = vueFlowInstance.project({ x: event.clientX - 40, y: event.clientY - 18 });

//var newinstance;
//onPaneReady((instance) => instance.fitView());



  function addWeights (parentID, weightID, dupID){
    let openEndedid = `weighted_node-`;
    if(dupID != null){
      openEndedid = `weighted_node-dup-${dupID}-`;
    }
    let y = 60 + 40 * weightID;
    const openEndedNode = {
      id: `${openEndedid + weightID}-to-${parentID}`,
      type: 'opennode',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: {
        x: 25,
        y: y,
      },
      extent: 'parent',
      parentNode: `question_node-${parentID}`,
      class: 'light',
      expandParent: true,
      selectable: false,
      draggable: false,
      data: { weightID:weightID, isWeight:true, parentID:parentID, weightValue: 0},
    };
    addNodes([openEndedNode]);
   
  }

/*
import { useEventBus } from '@vueuse/core';
const bus = useEventBus('addWeight');

bus.on((event, arg2) => {
  console.log(`addWeight: ${event} : ${arg2}`)
  // `e` will be `{ name: foo }`
})
*/
// listen to an event
//const unsubscribe = bus.on(listener);
/*
bus.on('addWeight', (id) => {
  console.log('event called');
  // `e` will be `{ name: foo }`
})*/



//bus.emit("10","test2");
//eventBus.$on('addWeight')

eventBus.on('AddNewWeight', (identity, weightValue) => {
  console.log(`add weight: ${identity[0]}` + " weight val: " + weightValue );
  addWeights (identity[0],identity[1],identity[2]);

  //eventBus.reset('addWeight');
 
})
/*
eventBus.off('addWeight', (id, arg2) => {
  console.log(`add weight turned off: ${id}`);
 
 
})
*/

const OnButtonPress = async() => {
        return new Promise((resolve) => {
            toggleDupMenu.value = true; 
            nextTick(function () {
                const createDup= document.getElementById('duplicateBtn')
                createDup.addEventListener('click', function() {
                    toggleDupMenu.value = false; 
                    resolve(1);
                });
                const linkDup = document.getElementById('linkBtn')
                linkDup.addEventListener('click', function() {
                    toggleDupMenu.value = false; 
                    resolve(2);
                });
                const cancelDup = document.getElementById('cancelDupBtn')
                cancelDup.addEventListener('click', function() {
                    toggleDupMenu.value = false; 
                    resolve(0);
                });

            });
        });
    }


const duplicateDataMap = new Map;

const onDrop = async (event) => {
  console.log('dropping node');
  const type = event.dataTransfer?.getData('application/vueflow');


  dropYPos.value = ref(event.clientY);
  dropXPos.value = ref(event.clientX);

  //todo: use type in if statement to work out what is being added

    const position = project({
      x: event.clientX,
      y: event.clientY - 40,
    });

if(type == 'questionnode'){
  const questionID = event.dataTransfer?.getData('questionID');
  const questionText = event.dataTransfer?.getData('questionText');
  const questionAnswers = JSON.parse(event.dataTransfer?.getData('questionAnswers'));
  const questionShort = event.dataTransfer.getData('questionShort');



  console.log(questionAnswers);
  console.log(Object.keys(questionAnswers.Answers).length);
  

  var answerQantity = Object.keys(questionAnswers.Answers).length;
  
  var questionHeight = 47 + 40 * answerQantity;
  var openended = false;
  if(answerQantity == 0){
    var questionHeight = 47 + 40+20;
    openended = true;
  }

  let questionid = `question_node-`;
  let answerID = `answer_node-`;
  let openID = `openended_node-to-`;
  let dupNum = null;
  const node = getNode.value(`question_node-${questionID}`) // pass nodeId 
  let createNode = true;

  let originalQID = questionid + questionID;
  if(!duplicateDataMap.has(originalQID)){
    duplicateDataMap.set(originalQID, new Map);
    duplicateDataMap.get(originalQID).set("duplicates",[0]);
    duplicateDataMap.get(originalQID).set("links",[0]);
    duplicateDataMap.get(originalQID).set("selectedlink",new Map);

  }


  if (node) {
      console.log("node already exists");
      const checkStatus = await OnButtonPress();

      console.log("status to continue is:" + checkStatus)
      if(checkStatus == 1){

        let dupPos = duplicateDataMap.get(originalQID).get("duplicates").length;
        dupNum = (duplicateDataMap.get(originalQID).get("duplicates")[dupPos - 1]) + 1;

        console.log(duplicateDataMap);
        console.log("dup num is: " + dupNum);
        questionid = `question_node-dup-${dupNum}-`;
        answerID = `answer_node-dup-${dupNum}-`;
        openID = `openended_node-dup-${dupNum}-to-`;
        duplicateDataMap.get(originalQID).get("duplicates").push(dupNum);
        eventBus.emit(originalQID + "-dupUpdate", duplicateDataMap.get(originalQID).get("duplicates") )
        console.log("Emitting dup map update: " + originalQID + "-dupUpdate");
      }else if (checkStatus == 2){

        //TODO: SEPERATE OUT THE DUPLINKS FROM THE DUPQUESTIONS into a 2D array

        let dupPos = duplicateDataMap.get(originalQID).get("links").length;
        dupNum = (duplicateDataMap.get(originalQID).get("links")[dupPos - 1]) + 1;

        console.log("Setup Question Link");
        createNode = false;
        questionid = `question_node-duplink-${dupNum}-`;

          const newlinkNode = {
            id: `${questionid + questionID}`,
            type: 'questionlinknode',
            label: 'parent',
            targetHandle: Position.Left, // or Bottom, Left, Right,
            sourceHandle: Position.Left,
            position: position,
            class: 'light',
            dragHandle: '.draghandle',
            data: { questionID: questionID, questionText: questionText, questionShort: questionShort, duplicateArray: duplicateDataMap.get(originalQID).get("duplicates"), dupNum:dupNum,
            },
          }
          console.log(duplicateDataMap);

        addNodes([newlinkNode]);
        duplicateDataMap.get(originalQID).get("links").push(dupNum);
        duplicateDataMap.get(originalQID).get("selectedlink").set(dupNum, 0);

        console.log("dup link info... ");
        console.log(dupNum);
        console.log(duplicateDataMap);
      }else{
        return;
      }
    }


if(createNode){
console.log("is open:" + openended)
  
  //const id = nodes.value.length + 1;
  const newNode = {
    id: `${questionid + questionID}`,
    type: 'questionnode',
    label: 'parent',
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position: position,
    style: {
      backgroundColor: 'rgb(232 232 232)',
      width: '200px',
      height: `${questionHeight}px`,
    },
    class: 'light',
    data: { questionID: questionID, questionText: questionText, questionShort: questionShort,openEnded: openended,answersQuantity:answerQantity,duplicate:dupNum
    },
    events: {
      /*click: () => {
        console.log('Node activated')
        
      },*/
      customEvent: () => {
        console.log('Node 1 custom event');
        //addWeights (questionID)
      },
    },
    /*
    , event:{
      click: () => {
        console.log('Node activated')
        addWeights (questionID)
      },
    },
    */
    
  };
  addNodes([newNode]);

  for (let i = 0; i < answerQantity; i++) {
    let y = 40 + 40 * i;
    const AnswerChildNode = {
      id: `${answerID + i}-to-${questionID}`,
      type: 'answernode',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: {
        x: 25,
        y: y,
      },
      extent: 'parent',
      parentNode: `${questionid + questionID}`,
      class: 'light',
      expandParent: true,
      draggable: false,
      selectable: false,
      data: { answerID: i + 1, answerText: questionAnswers.Answers[i]},
    };
    addNodes([AnswerChildNode]);
  }
  if(answerQantity == 0){
      let y = 60;
      const openEndedNode = {
      id: `${openID + questionID}`,
      type: 'opennode',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: {
        x: 25,
        y: y,
      },
      extent: 'parent',
      parentNode: `${questionid + questionID}`,
      class: 'light',
      expandParent: true,
      draggable: false,
      selectable: false,
      data: {weightID:`${openID + questionID}`, isWeight:false, parentID:questionID,weightValue: 0,},
    };
    addNodes([openEndedNode]);
  }
}
  }else if(type == 'interventionnode'){

    const interventionId = event.dataTransfer?.getData('interventionID');
    const interventionDesc =  event.dataTransfer?.getData('interventionDesc');
    const interventionTitle = event.dataTransfer?.getData('interventionTitle');

    const newNode = {
      id: `Intervention-Node-${interventionId}`,
      type: 'interventionnode',
      label: 'parent',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: position,/*{
        x: dimensions.value.width / 2,
        y: dimensions.value.height / 2,
      },*/
      data: { interventionText: interventionTitle, interventionDescription: interventionDesc },
    };
    addNodes([newNode]);
  }else if(type == 'treelinknode'){

    const treeLinkId = event.dataTransfer?.getData('treelinkID');
    const treeDesc =event.dataTransfer?.getData('treelinkDesc');
    const treeTitle =event.dataTransfer?.getData('treelinkTitle');


    const newNode = {
      id: `TreeLink-Node-${treeLinkId}`,
      type: 'treelinknode',
      label: 'parent',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: position, /*{
        x: dimensions.value.width / 2,
        y: dimensions.value.height / 2,
      },*/
      data: { treelinkText: treeTitle, treelinkDescription: treeDesc },
    };
    addNodes([newNode]);
  }
};

//'7', 'question_node-duplink-1-7', 1
eventBus.on('dupLinkChange', async (dupData) => {
  console.log("dup data recieved");
  console.log(dupData);
  duplicateDataMap.get(`question_node-` + dupData[0]).get("selectedlink").set(dupData[3], dupData[2]); 
  console.log(duplicateDataMap);
});

eventBus.on('LoadFlowTree', async (treeData) => {
  store.load(treeData);
  await nextTick(() => {
      console.log("text tick called.");
      store.populateWeights();
      flowTitle.value = ref(store.newtitle);
      flowDesc.value = ref(store.description);
      
  });
});

const flowTitle = ref('');
const flowDesc = ref('');

const loadEditTitleModal = async () =>{
  let createModalBD = document.getElementById('modal-backdrop');
  let createModal = document.getElementById('title-Modal');
  createModal.style.display = 'block';
  createModal.style.opacity = '1';
  createModalBD.style.display = 'block';
  createModalBD.style.opacity = '0.5';

  await nextTick();
}

const closeModal = async () =>{
        let createModalBD = document.getElementById('modal-backdrop');
        let createModal = document.getElementById('title-Modal');
        createModal.style.display = 'none';
        createModal.style.opacity = '0';
        createModalBD.style.display = 'none';
        createModalBD.style.opacity = '0';

        await nextTick(); 
      }

const saveTitleEdit = () => {
  store.newtitle = flowTitle.value;
  store.description = flowDesc.value;
  closeModal();

    if(savestatus){
    savestatus.value = false;
  }
}


const saveFlow = () =>{
  savestatus.value = true;
  store.log(duplicateDataMap)
}
</script>
<script>
/* eslint-disable */

import Vue from "vue";


export default {
  data() {
    return {
      xPos: 100,
      yPos: 100,
      qPanel: false,
      iPanel: false,
      tPanel: false,
      qePanel:false,
      kPanel: false,
      LPanel:true,
      rightClickPrevent: true,
    };
  },
  mounted(){

  },
  methods: {
    getPosition(event) {
      if(this.rightClickPrevent){
        event.preventDefault();
        this.xPos = event.clientX;
        this.yPos = event.clientY-60;
      }
    },
    openQuestionPanel () {
      this.qPanel = true;

      this.tPanel = false;
     
      this.iPanel = false;
    },
    openInterventionPanel () {
     this.iPanel = true;
      this.tPanel = false;
      this.qPanel = false;
     
    },
    openTreesPanel () {
     this.tPanel = true;
 
      this.qPanel = false;
      this.iPanel = false;
    },
    openQuestionEdit(){
      this.qePanel = true;
    },
    closePanel(){
      
      this.tPanel = false;
      this.qPanel = false;
      this.iPanel = false;
    },
    closeLoader(){
      this.LPanel = false;
    },
    closeQuestionEdit(){
      this.qePanel = false;
       this.qPanel = false;
    },
    openFlowLoader(){
      this.LPanel = true;
    },
    closeLibrary(){
      this.kPanel = false;
    },
    openLibrary(){
      this.kPanel = true;
    },
    debugRightClick(){
      this.rightClickPrevent = !this.rightClickPrevent
    },
  },
};

</script>

<template>
  <ConditionalSaveStatus v-if="!savestatus"/>
  <div class="tree-title"><p @click="loadEditTitleModal()">{{flowTitle.value}}<span style="padding-left: 10px;"><i class="fa-solid fa-pencil" style="
    font-size: 12px;
"></i></span></p></div>

  <FlowLoader v-if="LPanel" @closeLoader="closeLoader()"/>
  <QuestionEditor :questionEditorActive="qePanel" @closeQuestionEdit="closeQuestionEdit()"/>
  <KeywordLibrary v-if="kPanel" @closeLibrary="closeLibrary()" />
  <div class="dndflow" @drop="onDrop">
    <VueFlow
      v-model="store.elements"
      :fit-view-on-init="true"
      :node-types="nodeTypes"
      @edge-update="store.onEdgeUpdate"
      @edge-update-start="store.onEdgeUpdateStart"
      @edge-update-end="store.onEdgeUpdateEnd"
      @dragover="onDragOver"
      selection-key-code="Shift"
      @contextmenu="getPosition($event)"
     
    >

      <!--  
        @contextmenu="getPosition($event)"
      @mousedown.right="getPosition($event)"
      @contextmenu.prevent
    -->
      <AdditionalControls 
      :x-position="xPos" 
      :y-position="yPos" 
      @questionToggle="openQuestionPanel()"
      @interventionToggle="openInterventionPanel()"
      @treesToggle="openTreesPanel()"
      @debugRightClick="debugRightClick()"
      />
      <QuestionBar v-if="qPanel" @closePanel="closePanel()" @openQuestionEdit="openQuestionEdit()"/>
      <TreeBar v-if="tPanel" @closePanel="closePanel()"/>
      <InterventionBar v-if="iPanel" @closePanel="closePanel()"/>
      
      <div style="position: absolute; right: 10px; top: 10px; z-index: 4">
        <button class="flow-buttons btn-primary" @click="saveFlow()">Save Flow</button>
        <button class="flow-buttons btn-secondary" @click="openFlowLoader()">Open Flow</button>
        <button class="flow-buttons btn-secondary" @click="openLibrary()">Keywords</button>
      </div>
      <Background pattern-color="#aaa" gap="8" />

      <MiniMap />
      <Controls />
    </VueFlow >
    
  </div>

  <!-- Modal -->
  <div class="modal fade" id="title-Modal" tabindex="-1" aria-labelledby="title-ModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title-ModalLabel">Flow Title and Description</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="titleDescControlInput1" class="form-label">Title</label>
            <input type="text" class="form-control" id="titleDescControlInput1" placeholder="Flow Title" v-model="flowTitle.value">
          </div>
          <div class="mb-3">
            <label for="titleDescControlTextarea1" class="form-label">Description</label>
            <textarea class="form-control" id="titleDescControlTextarea1" placeholder="Flow Description" rows="3" v-model="flowDesc.value"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal()">Close</button>
          <button type="button" class="btn btn-primary"  @click="saveTitleEdit()">Save Changes</button>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-backdrop" class="modal-backdrop fade show" style="display: none;" @click="closeModal()"></div>

<div
    v-if="toggleDupMenu"
    :style="{top: dropYPos + 'px', left: dropXPos + 'px'}"
    class="card flow-menu"
  >
  <div class="card-header noselect">Question Already Used</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item noselect" id="duplicateBtn">
        Duplicate Question
      </li>
      <li class="list-group-item noselect" id="linkBtn">
        Link To Previous Question
      </li>
      <li class="list-group-item noselect" id="cancelDupBtn">
        Cancel
      </li>
    </ul>
  </div>

</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css');

@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.0.2/css/bootstrap.min.css');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flow-menu {
  position: absolute;
  background-color: #ffffff;
  z-index: 5;
  top: 200px;
  left: 400px;
  width: 14rem;
  height: auto;
}

.tree-title{
  position: absolute;
    text-align: center;
    width: 100%;
    color: #818181;
    pointer-events: none;
    font-weight: 100;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    z-index: 1010;
    margin-top: -34px;
}

.tree-title p{
  border-bottom: solid 1.5px #818181;
  padding: 0px 5px;
  pointer-events: fill;
}

/* 
Tooltip useage

- add class to parent container to activate on hover "active-tooltip"
- add class "vue-tooltip" to tooltip
*/

.left-tooltip{
  position:absolute;
  top:0;
  left:0;
  font-size: 9px;
  background-color: #2d3f56;
  color: white;
  font-weight: 600;
  border-radius:5px;
  padding:6px;
  margin:-6px;
  width:max-content;
  transform: translate(calc(-20px - 100%));
  display:none;
}

.left-tooltip::after{
  content:"";
  position:absolute;
  top:calc(50% - 8px);
  right:-4px;
  width: 0; 
  height: 0; 
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2d3f56;
  z-index:1001;
}


.right-tooltip{
  position:absolute;
  top:0;
  right:0;
  font-size: 9px;
  background-color: #2d3f56;
  color: white;
  font-weight: 600;
  border-radius:5px;
  padding:6px;
  margin:-6px;
  width:max-content;
  transform: translate(calc(100% + 20px));
  z-index:1001;
  display:none;
}

.right-tooltip::after{
  content:"";
  position:absolute;
  top:calc(50% - 8px);
  left:-4px;
  width: 0; 
  height: 0; 
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2d3f56;;
}

.top-tooltip{
  position:absolute;
  top: -10px;/*top:-100%;*/
  left:50%;
  font-size: 9px;
  background-color: #2d3f56;
  color: white;
  font-weight: 600;
  border-radius:5px;
  padding:6px;
  margin:-6px;
  width:max-content;
  transform: translate(calc( 50% - 100% + 6px),calc( -50%)); /* calc also adds the padding */
  z-index:1001;
  display:none;
}

.top-tooltip::after{
  content:"";
  position:absolute;
  bottom:calc(0% - 4px);
  left:calc(50% - 5px);
  width: 0; 
  height: 0; 
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #2d3f56;;
}

.bottom-tooltip{
  position:absolute;
  top:calc(200% + 6px);
  left:50%;
  font-size: 9px;
  background-color: #2d3f56;
  color: white;
  font-weight: 600;
  border-radius:5px;
  padding:6px;
  margin:-6px;
  width:max-content;
  transform: translate(calc( 50% - 100% + 6px),0);
  z-index:1001;
  display:none;
}

.bottom-tooltip::after{
  content:"";
  position:absolute;
  top:calc(0% - 4px);
  left:calc(50% - 5px);
  width: 0; 
  height: 0; 
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #2d3f56;;
}


.active-tooltip:hover .bottom-tooltip,.active-tooltip:hover .top-tooltip,.active-tooltip:hover .left-tooltip,.active-tooltip:hover .right-tooltip{
  display:block;
}

</style>
