<script>

import PostService from "../../services/PostService";
import eventBus from '../../event-bus.js';
import { blockStatement } from "@babel/types";
//import SocketioService from '../../services/socketio.service.js';

  export default {
    name: "SelectTreeView",
    components: {
      
    },
    data () {
      return {
        treelinkData:[],
        createModal: null,
        createModalBD: null,
        createTitle: "",
        createDesc: "",
      }
    },
    async created() {
/*
      var databaseString = await PostService.loadVueFlow(null);
    
      console.log(databaseString.data.body);
      this.treelinkData = databaseString.data.body;
      console.log(this.treelinkData[0].Title);
*/

      await this.loadTrees();
    },
    mounted(){
      this.createModal = document.getElementById('create-Flow-Modal');
      this.createModalBD = document.getElementById('modal-backdrop');
        
    },
    methods: {
      onClickButton (event, id) {
        //this.$emit('OpenFlow', this.treelinkData[id])
        eventBus.emit('LoadFlowTree',  this.treelinkData[id]);
        this.$emit('closeLoader');
        
        
      },
      openModal(){

        this.createModal.style.display = 'block';
        this.createModal.style.opacity = '1';
        this.createModalBD.style.display = 'block';
        this.createModalBD.style.opacity = '0.5';
  
      },
      closeModal(){
        this.createModal.style.display = 'none';
        this.createModal.style.opacity = '0';
        this.createModalBD.style.display = 'none';
        this.createModalBD.style.opacity = '0';
      },
      async CreateNew(event){
        if (this.createTitle != ""){
          var saveData = ""
          var response = await PostService.saveVueFlow(null, this.createTitle, null, this.createDesc, saveData, true, null , null);
          //console.log("create id: " + response.data.newID);
          let newID = Object.keys(this.treelinkData).length;

          await this.loadTrees();

          eventBus.emit('LoadFlowTree',  this.treelinkData[newID]);
          this.$emit('closeLoader');

        }else{
          console.log("tree title was blank");
          //Throw Error
        }
        this.closeModal();

        this.createDesc = "";
        this.createTitle = "";
      },
      async loadTrees(){
          var databaseString = await PostService.selectAll("trees");
          this.treelinkData = databaseString.data.body;
          console.log("treelink data");
          console.log(this.treelinkData);
      }


      },
  };
</script>
<template>
<div class="loader-panel-bg"></div>
<div class="loader-panel">
<div class="loader-contents">
<h2>Load</h2>
<hr/>
 <p>Session Flow diagrams which change the activity of the Virtual Coach</p>

 
  <div class="container loader-main-contents">

    <div class="row" >
      <div class="col-sm-3 mb-3">
        <div class="card mb-3">
          <div class="card-body">
            <div  class="card-title">Create a new Session Flow</div>
            <span class="create-session-btn" @click="openModal()" data-bs-toggle="modal" data-bs-target="#create-Flow-Modal"><i class="fa-solid fa-plus"></i></span>
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-3" v-for="(singleT, index) in treelinkData" :key="index">
        <div class="card mb-3">
          <div class="card-header">Screenshot of flow to go here...</div>
          <div class="card-body">
            <h5 class="card-title">{{singleT.title}}</h5>
            <p class="card-text">{{singleT.description}}</p>
            <a href="#" class="btn btn-primary" @click="onClickButton($event, index)">Edit Tree</a>
          </div>
        </div>
      </div>


  </div>

</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="create-Flow-Modal" tabindex="-1" aria-labelledby="create-Flow-ModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="create-Flow-ModalLabel">Create New Tree</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="tree-title-input" class="form-label">Tree Name</label>
          <input id="tree-title-input" class="form-control" type="text" placeholder="Tree..." aria-label=".form-control-lg example" v-model="createTitle">
        </div>
        <div class="mb-3">
          <label for="tree-desc-input" class="form-label">Tree Description</label>
          <textarea id="tree-desc-input" class="form-control" type="text" placeholder="Default input" aria-label="default input example" v-model="createDesc"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal()">Close</button>
        <button type="button" class="btn btn-primary"  @click="CreateNew($event, index)">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div id="modal-backdrop" class="modal-backdrop fade show" style="display: none;" @click="closeModal()"></div>
</template>

<style>


.loader-main-contents{
  overflow-y: auto;
  max-height: calc(80vh - 100px - 40px); /* height of div - height of header section - padding of parent */
}

.loader-panel{
  background-color:#ffffff;
  border-radius:10px;
  border: solid 1px #f2f2f2;
  width:80vw;
  height:80vh;
  position:absolute;
  z-index:1010;
  top:10vh;
  left:10vw;

}

.loader-panel .container{
  overflow-y: auto;
}


.loader-panel-bg{
  background-color:rgba(220,220,220,0.5);
  width:100vw;
  height:100vh;
  position:absolute;
  z-index:1005;
  top:0;
  left:0;
}

.loader-contents{
  text-align:left;
  padding:20px 30px;
}

.card{
  border: 1px solid rgba(0,0,0,.125);
  height: 100%;
}


.create-session-btn i{
    font-size: 2rem;
    text-align: center;
    width: 100%;
}


.create-session-btn{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}










  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
      outline-width: 0 !important;
      border-bottom: 2px solid black;
      outline: none;
  }
  .add-question-btn{
    width:auto;
  }
  .add-question-row{
    justify-content: center;
  }

  .modal-backdrop{
    display:none;
  }

  
.form-label{
  text-align:left;
  width: 100%;
}

</style>
