<template>
<div class="container">
  <div class="row">
    <h2>You have been logged out</h2>
</div>
</div>
</template>



<script>



import SocketioService from '../services/socketio.service.js';

export default {
  name: "LogoutView",
    components: {
      
    },
    data(){
      return {
        email: '',
        password: ''
      }
    },
    mounted(){
      // sets the user status to logged out within the socket.
      SocketioService.logOut();
      this.$router.push({ name: 'home' })
    },
    
};
</script>
