<template>
<div class="container">
  <h2 class="panel-title">Azure Speech</h2>
  <hr/>
  <p>View stats, Turn Scenes on and off dependant on statistic improvements.</p>
  <div class="container">
    

    <div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">
          <div class="card-body">
            <p>Filename:</p>
            <input type="text" placeholder="File Name" v-model="filename"/>
            <p>Text to speech</p>
            <textarea ref="inputTextBox" class="form-control" placeholder="Text to Speech" aria-label="TextSpeechBox" aria-describedby="button-addon2" v-model="textInput"></textarea>
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="sendText()">Button</button>
               
          </div>
        </div>
      </div>

    </div>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testLoginFromUnreal()">Test Unreal Login</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testAnswerFromUnreal()">Test Unreal Answer</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="saveVueFlow()">Test Save Vue Flow</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="translateFlowJson()">Test translate vue json</button>
    </div>
    <div>
      <textarea ref="paragraph" v-model="testArea"></textarea>
    </div>
    <div>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="saveInterventionFlow()">Test save intervention</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="loadInterventionFlow()">Test loadIntervention</button>
    </div>
    <div>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testSelectConstruct()">Test select construct</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testInsertIntoConstruct()">Test InsertInto construct</button>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testUpdateConstruct()">Test Update construct</button>
    </div>
    <div>
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="testPostGres()">Test postgres</button>
    </div>

</div>
</template>

<script>



import SocketioService from '../../services/socketio.service.js';
import PostService from "../../services/PostService";
//import socketioService from '../../services/socketio.service.js';

  export default {
    name: "AzurePanel",
    components: {
    },
    data () {

      return {
        conditionList: Array,
        textInput: "Hello",
        filename: "",
        count: 0,
        testArea: "",
        conditionStats: Array,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: true
          
        }
      }
    },
    methods: {
      async sendText(){
        try{
          await PostService.generateAudio(this.textInput, this.filename)
          //this.$router.push({ name: 'dashboard' })

        }catch(err){
          //this.error = "Please try again!"
          console.log("try again from page");
          //this.exception = true;
        }
      },

      // saves vue flow
      async saveVueFlow() {

        try{

          console.log("this is the test input:" + this.testArea);
          // input current tite, desired title, description, savedata
          // input ""/" "/null if nothing has changed except from current title as that is the ID
          var response = await PostService.saveVueFlow(1, null, null, "This is a Tree", this.testArea, false, null, null);
          console.log(response.data.message);

          if (response.data.message === 'error'){
            console.log(response.data.body);
          }
        }catch(err){
          //this.error = "Please try again!"
          console.log("try again from page");
          //this.exception = true;
        }
      },

      async saveInterventionFlow() {

        var response = await PostService.saveIntervention("InterventionExample", null, "This is a intervention", false);

        console.log("Save Intervention Response: " + response.data.message);
      },

      async loadInterventionFlow() {

        var response = await PostService.loadIntervention(1);

        console.log("Load Intervention Response: " + response.data.message + " Body: " + response.data.body); 
      },

      testLoginFromUnreal() {
        SocketioService.testUnrealLogin();
      },

      testAnswerFromUnreal() {
        SocketioService.testUnrealAnswer();
      },

      translateFlowJson() {
          SocketioService.translateFlowJsonPass();
      },

      async testSelectConstruct() {
        var response = await PostService.testSelectStatement();

        console.log("test select construct Response: " + response.data.message + " Body: " + response.data.body); 
      },

      async testInsertIntoConstruct() {
        var response = await PostService.testInsertIntoStatement();

        console.log("test InsertInto construct Response: " + response.data.message + " Body: " + response.data.body); 
      },

      async testUpdateConstruct() {
        var response = await PostService.testUpdateStatement();

        console.log("test Update construct Response: " + response.data.message + " Body: " + response.data.body); 
      },
      
      async testPostGres() {
        var response = await PostService.testPostGres();

        console.log("test Update construct Response: " + response.data.message + " Body: " + response.data.body); 
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20px; }
  .toggle.ios .toggle-handle { border-radius: 20px; }
  .toggle.android { border-radius: 0px;}
  .toggle.android .toggle-handle { border-radius: 0px; }

  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
      outline-width: 0 !important;
      border-bottom: 2px solid black;
      outline: none;
  }
  .add-question-btn{
    width:auto;
  }
  .add-question-row{
    justify-content: center;
  }

  body{
    overflow:hidden !important;
  }

</style>
