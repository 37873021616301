<script setup>
/* eslint-disable */
/*
var interventions ='[{"id":1,"Description":"Deleports the user to the top of the worry tree, working there way down each branch","Title":"Worry Tree Intervention"},{"id":2,"Description":"Mindfulness description","Title":"Mindfulness Intervention"},{"id":3,"Description":"5 Areas intervention description","Title":"5 Areas Intervention"},{"id":4,"Description":"Thought Record intervention description","Title":"Thought Record Intervention"}]';

var interventionData = JSON.parse(interventions);
console.log(interventionData[2].id);

const onDragStart = (event, nodeType, interventionID) => {
  console.log('drag started! ');
  if (event.dataTransfer) {
    event.dataTransfer.setData('application/vueflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('interventionID',interventionData[interventionID].id);
    event.dataTransfer.setData('interventionDesc',interventionData[interventionID].Description);
    event.dataTransfer.setData('interventionTitle',interventionData[interventionID].Title);

  }
};*/

</script>
<script>
/* eslint-disable */
import { defineComponent } from 'vue';
import PostService from "../../services/PostService";

export default defineComponent({
  // type inference enabled
  props: {
    
  },
  data() {
    return {
      toggle: false,
      interventionData: [],
    };
  },
  async created() {

    /*
    var interventions ='[{"id":1,"Description":"Deleports the user to the top of the worry tree, working there way down each branch","Title":"Worry Tree Intervention"},{"id":2,"Description":"Mindfulness description","Title":"Mindfulness Intervention"},{"id":3,"Description":"5 Areas intervention description","Title":"5 Areas Intervention"},{"id":4,"Description":"Thought Record intervention description","Title":"Thought Record Intervention"}]';

    this.interventionData = JSON.parse(interventions);
  */
    var databaseString = await PostService.selectAll("interventions");
    console.log('intervention Bar Loading in');
    console.log(databaseString.data.body);
    this.interventionData = databaseString.data.body;
    console.log(this.interventionData[0].title);

  },
  methods: {
    closePanel() {
      this.$emit('closePanel', true);
    },
    onDragStart (event, nodeType,  interventionID) {
     console.log('drag started! ');
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/vueflow', nodeType);
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('interventionID',this.interventionData[interventionID].id);
      event.dataTransfer.setData('interventionDesc',this.interventionData[interventionID].description);
      event.dataTransfer.setData('interventionTitle',this.interventionData[interventionID].title);

    }
    
    },
  },
});
</script>
<template>
  <aside>
    <div class="closeBtn" @click="closePanel">Close</div>
    <div v-for="(singleI, index) in interventionData" :key="index">
    <div class="nodes">
      <div
        class="vue-flow__node-select"
        :draggable="true"
        @dragstart="onDragStart($event, 'interventionnode', index)">
        {{singleI.title}}
      </div>
    </div>
    </div>
  </aside>
</template>

<style>
.closeBtn{
  color:black;
}

</style>