<template>

<nav class="navbar navbar-expand-lg navbar-light sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" @click="changeRoute('home')">VR Health Dashboard</a>
    <i class="bi bi-grid-3x3-gap" v-on:click="menuVisible = !menuVisible"></i>
      
  </div>
</nav>

<div class="row">
  <div id="page-content" class="col">
          <Questions v-if="currentViewPanel == 'Questions'" />
          <DashHome v-if="currentViewPanel == 'DashHome'"/>
          <Environments v-if="currentViewPanel == 'Environments'" />
          <Analytics v-if="currentViewPanel == 'Analytics'" :permissions="permissions" />
          <Azure v-if="currentViewPanel == 'Azure'" />
          <VueFlow v-if="currentViewPanel == 'VueFlow'" @savestatus="status"/>
          <ActiveSessionsView v-if="currentViewPanel == 'ActiveSessionsView'"/>
          <QuestionnaireView  v-if="currentViewPanel == 'Questionnaire'" 
          :id="29"
          :questionairType="'admin'"
          />
          <SessionAllocation  v-if="currentViewPanel == 'SessionAllocation'"/>
  </div>
  <div id="dash-menu" class="d-none d-md-block col-12 col-md-3 col-xl-2 sticky-top" v-if="menuVisible">
        <div class="row dash-menu-item" @click="changeVue('DashHome')">
          <div class="col-3 menu-icons"><i class="fas fa-file-alt fa-2x"></i></div>
          <div class="col menu-header"><h6>Dash Home</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeVue('Azure')" v-if="permissions >= 3">
          <div class="col-3 menu-icons"><i class="fas fa-chart-line fa-2x"></i></div>
          <div class="col-7 menu-header "><h6>Azure</h6></div>
        </div>
        <div class="row dash-menu-item" @click="openVueFlow" v-if="permissions >= 2">
          <div class="col-3 menu-icons"><i class="fa-solid fa-diagram-project fa-2x"></i></div>
          <div class="col-7 menu-header "><h6>Session Trees</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeVue('Analytics')">
          <div class="col-3 menu-icons"><i class="fas fa-chart-line fa-2x"></i></div>
          <div class="col-7 menu-header "><h6>Analytics</h6></div>
        </div>
        <div style="display:none;" class="row dash-menu-item" @click="changeVue('Environments')" >
          <div class="col-3 menu-icons"><i class="fa-solid fa-earth-europe fa-2x"></i></div>
          <div class="col menu-header"><h6>Environments</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeVue('ActiveSessionsView')" v-if="permissions >= 2">
          <div class="col-3 menu-icons"><i class="fa-solid fa-table-tennis-paddle-ball fa-2x"></i></div>
          <div class="col menu-header"><h6>Active Sessions</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeVue('Questions')" v-if="permissions >= 2">
          <div class="col-3 menu-icons"><i class="fa-solid fa-question fa-2x"></i></div>
          <div class="col menu-header"><h6>Q an A</h6></div>
        </div>
        
        <div class="row dash-menu-item" @click="changeVue('Questionnaire')" >
          <div class="col-3 menu-icons"><i class="fa-solid fa-clipboard-question fa-2x"></i></div>
          <div class="col menu-header"><h6>Questionnaire</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeVue('SessionAllocation')" v-if="permissions >= 2">
          <div class="col-3 menu-icons"><i class="fa-solid fa-timeline fa-2x"></i></div>
          <div class="col menu-header"><h6>Session Allocation</h6></div>
        </div>
       
        <div class="row dash-menu-item" @click="changeVue('Settings')" style="display:none;">
          <div class="col-3 menu-icons"><i class="fas fa-cog fa-2x"></i></div>
          <div class="col menu-header"><h6>Settings</h6></div>
        </div>
        <div class="row dash-menu-item" @click="changeRoute('logout')">
          <div class="col-3 menu-icons"><i class="fas fa-power-off fa-2x"></i></div>
          <div class="col menu-header"><h6>Log Out</h6></div>
        </div>
  </div> 
</div>

</template>

<script>

import Questions from '../components/Dashboards/Questions.vue'
import VueFlow from '../components/Dashboards/VueFlowView.vue'
import DashHome from '../components/Dashboards/DashHomeView.vue';
import Environments from '../components/Dashboards/EnvironmentsView.vue';
import Analytics from '../components/Dashboards/AnalyticsView.vue';
import Azure from '../components/Dashboards/AzureView.vue';
import ActiveSessionsView from '../components/Dashboards/ActiveSessionsView.vue';
import QuestionnaireView from '../components/Dashboards/QuestionnaireView.vue';
import eventBus from '../event-bus.js';
import SessionAllocation from '../components/Dashboards/SessionAllocation.vue';
//import { tsImportEqualsDeclaration } from '@babel/types';
import SocketioService from '../services/socketio.service.js';
import Vue from 'vue';

export default {
  name: "DashboardView",
    components: {
      Questions,
      DashHome,
      Environments,
      Analytics,
      Azure,
      VueFlow,
      ActiveSessionsView,
      QuestionnaireView,
      SessionAllocation,
    },
    data () {
        return {
            menuVisible: true,
            currentViewPanel: 'DashHome',
            savestatus: true,
            permissions: 0,
        }
    },
    async mounted (){
     this.$nextTick( async () => {
        console.log('permissions = ' + this.permissions )

        let result = await SocketioService.checkPermissions();
        if(result[0] == 'success'){
        this.permissions = result[1] ;
        console.log(result);
        }
        console.log('permissions = ' + this.permissions )
      });
    },
    methods: {
      openVueFlow(){
        this.currentViewPanel = 'VueFlow';
        this.menuVisible = false;
      },
      async changeVue (newVue){
        if(this.savestatus != true){
          const answer = await window.confirm(
            'Do you really want to leave? you have unsaved changes!'
          )
          // cancel the navigation and stay on the same page
          if (!answer){
            console.log("save status set to..." + this.savestatus);
          }else{
            console.log("save status set to..." + this.savestatus);
            this.currentViewPanel = newVue;
          }
        }else{
          this.currentViewPanel = newVue;
        }
      },
      status(value){
        console.log("save status changing: ..." + value);
        this.savestatus = value;
      },
      async changeRoute(newRoute){
         const answer = await window.confirm(
          'Do you really want to leave? you have unsaved changes!'
        )
        // cancel the navigation and stay on the same page
        if (!answer){
          console.log("save status set to..." + this.savestatus);
        }else{
          console.log("save status set to..." + this.savestatus);
           this.$router.push({ name: newRoute })
        }
      
      }
    },
   
  /*
  // standard VUE.JS template example for using props
  props: {
    msg: String
  }*/
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dash-menu{
  background-color:var(--bg-offcolor);
  height:calc(100vh - 56px);
  overflow-y:auto;
  top: 56px;
  color: white;
    font-weight: bold;
}
.menu-icons{
  text-align:center;
}


.menu-header{
  vertical-align:middle;
  text-align:left;
  margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-left:0;
}

.menu-header h6{
  margin:0;
}

.row.dash-menu-item:hover {
    background: #619d9e;
    cursor: pointer;
}

.dash-menu-item{
  padding: 30px 0 30px 20px;
  /*margin: 40px 0px;*/
}

</style>
