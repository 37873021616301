<template>
<MenuComponent />

<div class="container form-container">
  <div class="row">

  <form class="form-contents">
  <div class="mb-3">
    <label for="emailField" class="form-label">Email address</label>
    <input type="email" class="form-control" id="emailField" aria-describedby="emailHelp" v-model="email">
    
  </div>
  <div class="mb-3">
    <label for="passwordField" class="form-label">Password</label>
    <input type="password" class="form-control" id="passwordField" v-model="password">
    <div id="passwordHelp" class="form-text">Forgotten Password?</div>
  </div>
  <!--<div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  -->
   <div class="col-12 alert alert-danger" role="alert" v-if="error != null">{{error}}</div>
  
  <button type="button" class="btn vr-btn-primary" @click="handleLogin()">Login</button>
  <button type="button" class="btn vr-btn-secondary booking-btn"  @click=" this.$router.push({ name: 'booking' })">Booking Form</button>
</form>
</div>
</div>
</template>

<script>
//import axios from 'axios';
//import UserService from '../services/Users.service'
//import Api from "../services/Api";
import PostService from "../services/PostService";
//import SocketioService from '../services/socketio.service.js';
import MenuComponent from '../components/MenuComponent.vue';

//import SocketioService from '../services/socketio.service.js';

export default {
  name: "LoginView",
    components: {
      MenuComponent
    },
    data(){
      return {
        email: '',
        password: '',
        error: null,
        exception: false
      }
    },
    setup(){
      //let x = this.$cookies.get("sessionCookie");
      //console.log("sessionCookie = " + x)
    },  
    methods: {
      async handleLogin(){
        //var loginSussessful = SocketioService.loginUser(this.email,this.password);
        //console.log(loginSussessful);
        //let result = await axios.get(`http://localhost:3000/users?email=${this.email}&password=${this.password}`);
        //console.log(result);


        //var response = UserService.loginToAccount(this.email,this.password);
        //console.log('anything here: ' + response);
/*

        axios.post('http://localhost:3000/login', {
          email: 'Finn',
          password: 'Williams'
        })
        .then((response) => {
          console.log(response);
        }, (error) => {
          console.log(error);
        });

*/

        try{
          var secret = await PostService.loginUser(this.email, this.password);
          this.$router.push({ name: 'dashboard' })

        }catch(err){
          this.error = "Please try again!"
          console.log("try again from page");
          this.exception = true;
        }

      }
    }
      
    

   
  /*props: {
    msg: String
  }*/
};
</script>

<style scoped>


.form-container{
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(74, 79, 97, 0.25) 0px 13px 27px -5px, rgba(74, 79, 97, 0.3) 0px 8px 16px -8px;
  padding: 20px 35px 35px 35px;
  text-align: center;
  width: 500px;
  margin-top: 25vh;
}


.form-contents{
  text-align: left;
}

.booking-btn{
  margin-left:5px;
}


</style>