<template>
<div class="ConditionalStatus"></div>
</template>
<script setup>

import { computed,onMounted,nextTick, onBeforeUnmount} from 'vue';

onMounted(async () => {
    console.log('mounted conditional status');

    window.addEventListener("beforeunload", (e) => {
            e.preventDefault();
            const message = "Are you sure you wish to leave? You may have unsaved changes."
            e.returnValue = message
            
            return message
    });
})

onBeforeUnmount( () => {
    console.log('unmounted conditional status');

    window.addEventListener("beforeunload", (e) => {

                const message = ""
                e.returnValue = message
                
                return message
        });
})





</script>