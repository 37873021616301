import axios from "axios";
import SocketioService from './socketio.service.js'

const url = "/api/posts";

/* eslint-disable no-async-promise-executor */

/*
        how input should be formatted:
        select
        var queryConfig = {
            allColumns : true,
            columns : columns,
            from : "table name",
            where : "ID"
        }
        var columns = {
            columns that you want from database
        }
        */

        /*
        insert
        how input should be formatted:
        var queryConfig = {
            columns : columns,
            from : "table name",
            values : []
        }
        var columns = {
            columns that you want to insert data into
        }
        */
       /*
       update
        how input should be formatted:
        var queryConfig = {
            columns : columns,
            from : "table name",
            where : [where condition],
            values : []
        }
        var columns = {
            columns that you want to update
        }
        */
       /*
       delete
        how input should be formatted:
        var queryConfig = {
            from : "table name",
            where : "ID",
            values : []
        }
        */

class PostService {
  // get posts
  static getPosts() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(url);
        const data = res.data;
        resolve(
          data.map((post) => ({
            ...post,
            createdAt: new Date(post.createdAt),
          }))
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  //Create post
  static loginUser(email, password) {
    return new Promise(async (res, reject) => {
      try {
        console.log("sending Login Request");

        let queryConfig = {
          allColumns : true,
          from : "users",
          where : ["username", "password"],
          values : [email, password]
        }
        
        return axios.post(url+'/loginUser',  {
          queryConfig
        }).then((response) => {
          console.log('Login response: ' + response.data.message)
          //console.log(response.data);

          if(response.data.success){
            console.log("pre socket auth update message")
            //response.data.id
            SocketioService.setLoginCode(response.data.loginCode);
            console.log(response.data);
            SocketioService.setAuth(true, email,response.data.id);//routers.setAuth(true);
            res(response);

          }else{
            reject('error');
          }
        });

      } catch (err) {
        reject(err);
      }
    });
  }

  static updateQuestion(question){

    return new Promise(async (res, reject) => {
      try {
        console.log("Updating Question...");

        let columns = {
          questionshort : "questionshort",
          question : "question",
          answers : "answers",
          type : "type",
          session_allocation : "session_allocation"
        }

        let queryConfig = {
          columns : columns,
          from : "questions",
          where : ["id"],
          values : [question.questionshort, question.question, question.answers, question.type, question.session_allocation, question.id]
        }

        return axios.post(url +'/update',  {
          queryConfig
        }).then((response) => {
          console.log(response)
          res(response)
        });

      } catch (err) {
        reject(err);
      }
    })
  }

  static insertQuestion(question){
    return new Promise(async (res, reject) => {
      try {
        console.log("Inserting Question...");
        return axios.post(url+'/insertQuestions',  {
          question
        }).then((response) => {
          console.log('PostService Response' + response)
          res(response)
        });

      } catch (err) {
        reject(err);
      }
    })
  }


  static generateAudio(text, filename){
    return new Promise(async (res, reject) => {
      try {
        console.log("Sending text to change to audio...");
        return axios.post(url+'/inputText',  {
          text, filename
        }).then((response) => {
          console.log('PostService Response' + response)
          res(response)
        });

      } catch (err) {
        reject(err);
      }
    })
  }


  static updateAccount(AccountInfo){
    return new Promise(async (res, reject) => {
      try {
        console.log("Updateing Account...");
        return axios.post(url+'/updateAccount',  {
          AccountInfo
        }).then((response) => {
          console.log('PostService Response' + response)
          res(response)

        });

      } catch (err) {
        reject(err);
      }
    })   
  }

  
  static getQuestions(){
    return new Promise(async (res, error) => {
      try {
        console.log("Requestions for questions");
        axios.get(url + '/questions').then((response) => {
          console.log(response)
          return res(response);

        });

      } catch (err) {
        return error(err)
      }
    })
    
      
  }
/*
  static createAccount(email,password,name,age){
    return new Promise(async (res, reject) => {
      try {
        console.log("Create Account Request...");

        let queryConfig = {
          allColumns : true,
          from : "users",
          where : ["username"],
          values : [email, password, age, name]
        }
        return axios.post(url+'/createAccount',  {
          queryConfig
        }).then((response) => {
          console.log('create account response: ' +  response.data.success)

          if( response.data.success){
            console.log("pre socket auth update message")
            console.log(response.data.result.rows[0])
            SocketioService.setLoginCode(response.data.loginCode);//value, username,id
            SocketioService.setAuth(true,email,response.data.id);//routers.setAuth(true);
            res(response);

          }else{
            //response.data.message;
            reject('error');
          }
        });

      } catch (err) {
        reject(err);
      }
    });
   }*/

   static saveVueFlow(inTreeID, inTitle, inNewTitle, inDescription, inSaveData, isNew, inWeights, inWeightOverrides, indupLinkMap){
    return new Promise(async (res, reject) => {
      try {
        console.log("title before manipulation: " + inTitle);
        // change url added string to a different function if creating a new tree

        console.log("Title after manipulation: " + inTitle);

        if(inDescription == null){
          inDescription = " ";
        }

        var functionUrl = "/";
        if (isNew) {
          functionUrl += "vueFlowCreate";
          return axios.post(url+functionUrl,  {
            title : inTitle,
            description : inDescription,
          }).then((response) => {
            console.log(functionUrl + ' Response: ' + response.data.message)
            res(response)
          });

        } else {
          functionUrl += "vueFlowSave";

          inTitle = inNewTitle;

          return axios.post(url+functionUrl,  {
            ID : inTreeID,
            title : inTitle,
            description : inDescription,
            weights: inWeights,
            weightoverrides: inWeightOverrides,
            savedata : inSaveData,
            duplicatelinks : indupLinkMap
          }).then((response) => {
            console.log(functionUrl + ' Response: ' + response.data.message)
            res(response)
          });
        }




      } catch (err) {
        reject(err);
      }
    })
  }

  static loadVueFlow(inID){

    return new Promise(async (res, reject) => {
      try {
        // change url added string to a different function if creating a new tree
        return axios.post(url+ "/vueFlowLoad",  {
          ID : inID,
        }).then((response) => {
          console.log('/vueFlowLoad Response: ' + response.data.message)
          res(response)
        });
      } catch (err) {
        reject(err);
      }
    })
  }

  static saveIntervention(inTitle, inNewTitle, inDescription, isNew){
    return new Promise(async (res, reject) => {
      try {
        // change url added string to a different function if creating a new tree

        var functionUrl = "/";
        if (isNew) {
          functionUrl += "vueInterventionCreate";
        } else {
          functionUrl += "vueInterventionSave";
        }

        if (inNewTitle == null){
          inNewTitle = inTitle;
        }

        return axios.post(url+functionUrl,  {
          title : inTitle,
          newTitle : inNewTitle,
          description : inDescription
        }).then((response) => {
          console.log(functionUrl + ' Response: ' + response.data.message)
          res(response)
        });

      } catch (err) {
        reject(err);
      }
    })
  }

  static loadIntervention(inID){

    return new Promise(async (res, reject) => {
      try {
        // change url added string to a different function if creating a new tree
        return axios.post(url+ "/vueInterventionLoad",  {
          ID : inID,
        }).then((response) => {
          console.log('/vueInterventionLoad Response: ' + response.data.message)
          res(response)
        });
      } catch (err) {
        reject(err);
      }
    })
  }


static saveKeywordLibrary(inID, inKeyword, inWeight, isNew){
  return new Promise(async (res, reject) => {
    try {
      // change url added string to a different function if creating a new tree

      var functionUrl = "/";
      if (isNew) {
        functionUrl += "KeywordLibraryCreate";
      } else {
        functionUrl += "KeywordLibrarySave";
      }

      return axios.post(url+functionUrl,  {
        ID : inID,
        keyword : inKeyword,
        weight : inWeight,
      }).then((response) => {
        console.log(functionUrl + ' Response: ' + response.data.message)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static loadKeywordLibrary(inID){

  return new Promise(async (res, reject) => {
    try {
      // change url added string to a different function if creating a new tree

      let queryConfig = {
        allColumns : true,
        from : "keywordlibrary",
        where : ["id"],
        values : [inID]
      }

      return axios.post(url+ "/select",  {
        queryConfig
      }).then((response) => {
        console.log('/KeywordLibraryLoad Response: ' + response.data.message)
        res(response)
      });
    } catch (err) {
      reject(err);
    }
  })
}

static insertComment(comment){
  return new Promise(async (res, reject) => {
    try {
      console.log("Inserting comment to return ID...");

      let columns = {
        message : "comment"
      }

      let queryConfig = {
        columns : columns,
        from : "comments",
        values : [comment]
      }
      return axios.post(url+'/insert',  {
        queryConfig
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static insertMessage(message){
  return new Promise(async (res, reject) => {
    try {
      console.log("Inserting Message to return ID...");

      let columns = {
        message : "message"
      }

      let queryConfig = {
        columns : columns,
        from : "messages",
        values : [message]
      }
      return axios.post(url+'/insert',  {
        queryConfig
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static updateComment(id, comment){
  return new Promise(async (res, reject) => {
    try {
      console.log("Inserting comment to return ID...");

      let columns = {
        message : "comment"
      }

      let queryConfig = {
        columns : columns,
        from : "comments",
        where : ["id"],
        values : [comment, id]
      }

      return axios.post(url+'/update',  {
        queryConfig
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static updateMessage(id, message){
  return new Promise(async (res, reject) => {
    try {
      console.log("Inserting Message to return ID...");

      let columns = {
        message : "message"
      }

      let queryConfig = {
        columns : columns,
        from : "messages",
        where : ["id"],
        values : [message, id]
      }
      return axios.post(url+'/update',  {
        queryConfig
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static deleteComment(ID, comment){
  return new Promise(async (res, reject) => {
    try {
      console.log("delete comment");
      return axios.post(url+'/deletecomment',  {
        comment, ID
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static deleteMessage(ID, message){
  return new Promise(async (res, reject) => {
    try {
      console.log("delete Message");
      return axios.post(url+'/deletemessage',  {
        message, ID
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static selectComment(ID){
  return new Promise(async (res, reject) => {
    try {
      console.log("Select comment");
      return axios.post(url+'/selectcomment',  {
        ID
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static selectMessage(ID){
  return new Promise(async (res, reject) => {
    try {
      console.log("Select Message");
      return axios.post(url+'/selectmessage',  {
        ID
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static testSelectStatement(){
  return new Promise(async (res, reject) => {
    try {
      return axios.post(url+'/testSelectConstruct',  {
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static testInsertIntoStatement(){
  return new Promise(async (res, reject) => {
    try {
      return axios.post(url+'/testInsertIntoConstruct',  {
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static testUpdateStatement(){
  return new Promise(async (res, reject) => {
    try {
      return axios.post(url+'/testUpdateConstruct',  {
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static testPostGres(){
  return new Promise(async (res, reject) => {
    try {
      return axios.post(url+'/testPostGres',  {
      }).then((response) => {
        console.log('PostService Response' + response)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

static createAccount(email,password,name,age){
  return new Promise(async (res, reject) => {
    try {
      console.log("Create Account Request...");

      let queryConfig = {
        allColumns : true,
        from : "users",
        where : ["username"],
        values : [email, password, age, name]
      }
      return axios.post(url+'/createAccount',  {
        queryConfig
      }).then((response) => {
        console.log('create account response: ' +  response.data.success)

        if( response.data.success){
          console.log("pre socket auth update message")
          console.log(response.data.result.rows[0].id)
          SocketioService.setLoginCode(response.data.loginCode);
          SocketioService.setAuth(true,email,response.data.result.rows[0].id);//routers.setAuth(true);
          res(response);

        }else{
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }


 static saveSessionAllocation(sessionNumber, treeID, isNew){
  return new Promise(async (res, reject) => {
    try {
      // change url added string to a different function if creating a new tree

      var functionUrl = "/";
      if (isNew) {
        functionUrl += "SessionAllocationCreate";
      } else {
        functionUrl += "SessionAllocationSave";
      }

      return axios.post(url+functionUrl,  {
        id : sessionNumber,
        treeid : treeID,
      }).then((response) => {
        console.log(functionUrl + ' Response: ' + response.data.message)
        res(response)
      });

    } catch (err) {
      reject(err);
    }
  })
}

 static delete(id, table){
  return new Promise(async (res, reject) => {
    try {

      let queryConfig = {
        from : table,
        where : ["id"],
        values : [id]
      }
      return axios.post(url+'/delete',  {
        queryConfig
      }).then((response) => {
        console.log('create account response: ' +  response.data.success)

        if( response.data.success){
          console.log("pre socket auth update message")
          SocketioService.setLoginCode(response.data.loginCode);
          SocketioService.setAuth(true);//routers.setAuth(true);
          res(response);

        }else{
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }

 static selectAll(table){
  return new Promise(async (res, reject) => {
    try {

      let queryConfig = {
        allColumns : true,
        from : table,
        where : [],
        values : []
      }

      return axios.post(url+'/select',  {
        queryConfig
      }).then((response) => {
        console.log('selecting all: ' +  response.data.success)

        if(response.data.success){
          res(response);
        }else{
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }

 static select(table, id){
  return new Promise(async (res, reject) => {
    try {

      let queryConfig = {
        allColumns : true,
        from : table,
        where : ["id"],
        values : [id]
      }

      return axios.post(url+'/select',  {
        queryConfig
      }).then((response) => {
        console.log('selecting all: ' +  response.data.success)

        if(response.data.success){
          res(response);
        }else{
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }

 static update(table, columns, values){
  return new Promise(async (res, reject) => {
    try {

      let queryConfig = {
        columns : columns,
        from : table,
        where : ["id"],
        values : values
      }

      return axios.post(url+'/update',  {
        queryConfig
      }).then((response) => {
        console.log('selecting all: ' +  response.data.success)

        if(response.data.success){
          res(response);
        }else{
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }

 static insert(table, columns, values){
  return new Promise(async (res, reject) => {
    try {

      let queryConfig = {
        columns : columns,
        from : table,
        values : values
      }

      return axios.post(url+'/insert',  {
        queryConfig
      }).then((response) => {
        console.log('selecting all: ' +  response.data.success)

        if(response.data.success){
          res(response);
        }else{
          console.log(response);
          //response.data.message;
          reject('error');
        }
      });

    } catch (err) {
      reject(err);
    }
  });
 }
}



export default PostService;
