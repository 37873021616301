<template> <!-- start of question card -->
      <div class="card q-an-a-card q-an-a-1">
        <div class="card-header row">
          <div class="col-12 question-title-header">
            <h5 class="card-title">Q{{relationship}}&#41;</h5>
            <input v-if="editMode" class="q-an-a-title-input" type="text" v-model="updatedQ" autofocus/>
            <h5 class="question-text" v-else>
              <span>{{updatedQ}}</span>
            </h5>
            <span class="card-edit-btn vr-btn-primary">
            <i @click="saveData(),editMode = !editMode" v-if="editMode" class="fa-regular fa-circle-check"></i>
            <i @click="editMode = !editMode" v-else class="fa-solid fa-pencil"></i>
          </span>
          </div>
          <div class="col-12 question-title-header">
            <h6 class="card-title">Q Short &#41;</h6>
            <input v-if="editMode" class="q-an-a-title-input" type="text" v-model="updatedQs" maxlength="22"/>
            <h6 class="question-text question-short-text" v-else>
              <span>{{updatedQs}}</span>
            </h6>
          </div>
        </div>
        <div class="card-body">
          <div v-if="!answers.length">Open ended Question, no specified answers.</div>
          <div class="answer-row" v-for="(updatedA, index) in updatedAs" :key="updatedA.id">
            <span class="answer-number">{{index + 1}} &#41;</span>
            <input v-if="editMode" class="q-an-a-answer-input" type="text" v-model="updatedAs[index]"/>
            <div @click="removeAnswer(index)" v-if="editMode" class="remove-answer">
              <i class="fa-solid fa-minus"></i>
            </div>
            <p v-else class="card-text">{{updatedA}}</p>
            
          </div>
          <div @click="addAnswer()" v-if="editMode" class="add-answer">
            <i class="fa-solid fa-plus"></i>
          </div>

        </div>
        <div class="card-footer text-muted">
          <div class="input-group mb-3">
            <span class="input-group-text" id="type-selector">Type</span>
            <select class="form-control" aria-describedby="type-selector" v-model="updatedT">
              <option value="3">Open Ended (Default if no answers)</option>
              <option value="2">Slider</option>
              <option value="1">Emoji</option>
            </select>
          </div>
        </div>
      </div> <!-- end of question card -->
</template>

<script>
import { ConnectionLineType } from '@braks/vue-flow';


  export default {
    name: "QuestionTemplate",
    // sets the values from the parent for displaying on the card
    props: {
      question: String,
      questionshort: String,
      answers: [Number, String, Object],
      allocation: Number,
      questionID: Number,
      relationship: String,
      mode: Boolean,
      type: String,
    },
    data () {
      
        return {
            editMode:this.mode,
            updatedQ:this.question,
            updatedQs:this.questionshort,
            updatedAs:this.answers,
            updatedS:this.allocation,
            updatedR:null,
            updatedT:this.type,
            newRelationship:null,         
        }
    },
    
    created(){
      // setting the relationship between the different questions
      // to displays 
      if(this.relationship % 1 != 0){
        this.updatedR = this.relationship;// - 0.1;
      }
      //console.log('QuestionShort Inside question:' + this.updatedQs + ' For Question: ' + + this.updatedQ)
      
    },
    methods:{
      saveData(){

        //
        /*if(this.updatedR != null){
          this.newRelationship = this.updatedR + 0.1;
        }
          console.log(this.newRelationship);*/

          // emits the update back to the parent for saving into the database
        this.$emit('updateQuestion',  { q:this.updatedQ, qs:this.updatedQs, a:this.updatedAs, s: this.updatedS, id: this.questionID, r:this.relationship, allo: this.allocation, t:this.updatedT});//this.updatedQ, this.updatedAs, this.updatedS);

      },
      removeAnswer(id){
        this.updatedAs.splice(id, 1);
      },
      addAnswer(){
        this.updatedAs.push("");
      }

    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }


  .q-an-a-card{
    margin-bottom: 15px;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
    font-weight: 500;
    padding:0;

    outline-width: 0 !important;
    border-bottom: 1px solid  var(--bah-secondary);
    outline: none;
    margin-bottom: -1px;
    flex-grow: 1;
    margin-left:5px;
    margin-right:5px;
  }

  .q-an-a-answer-input{
    border:none;
    background-color:transparent;
    font-size: 1rem;
    padding:0;
    font-weight: 400;

    outline-width: 0 !important;
    border-bottom: 1px solid  var(--bah-secondary);
    outline: none;
    margin-bottom: -1px;
    flex-grow: 1;

  }

  .add-answer{
    text-align:right;
    color: var(--bah-primary);
    padding: 6px 0;
  }

  .remove-answer{
    color:red;
    font-weight:bold;
    /*font-size:22px;*/
    padding: 0 0 0 30px;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
    outline-width: 0 !important;
    border-bottom: 1px solid  var(--bah-primary);
    outline: none;
    margin-bottom: -1px;
  }

  .answer-row{
    display: flex;
    padding: 6px 0;
  }

  .answer-number{
    padding-right:10px;
  }

  .question-text{
    flex-grow: 1;
    padding-left:5px;
    
  }

  .question-title-header{
    display: flex;
    align-items: left;
    justify-content: space-between;
    text-align:left;
  }

  .question-short-text{
    margin-right: 45px;
  }
</style>
