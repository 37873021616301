<script setup>
    /* eslint-disable */
    import { ref, nextTick } from 'vue';

    const props = defineProps({
        keyword: String,
        weight: String,
        index: Number,
        id: Number,
    })

    var newWeight = props.weight;
    var newKeyword = props.keyword;

    const editMode = ref(false);

    const emit = defineEmits(['update', 'delete'])

/*
    const emit = defineEmits<{
        (e: 'update', keyword: String|undefined, weight: String|undefined, id: number|undefined): void
        (e: 'delete', id: number|undefined): void }>()

*/
    const updateKeyword = (keyword, weight, id) => {
        editMode.value = false;
        console.log("update keyword data to: " + newKeyword + " : " + newWeight + " Where id = " + props.id);
        emit('update', keyword, weight, id );
        //await nextTick();
    }

    const editKeyword = async () => {
        editMode.value = true;
        console.log("edit button activated")
        await nextTick();

    }

    const deleteKeyword = () => {
        emit('delete', props.id ); 
        //await nextTick();
    }
    // setup code
</script>


<template>
    <div class="col-4 keyword-item">
        <input v-if="editMode" type="text" v-model="newKeyword" />
        <p v-else >{{newKeyword}}</p>
        <div class="keyword-options">
            <input v-if="editMode" type="text" v-model="newWeight" />
            <input v-else type="text" v-model="newWeight" disabled />
            <span>
              <i v-if="editMode" @click="updateKeyword(newKeyword, newWeight,props.id)" class="fa-solid fa-check"></i>
              <i v-if="!editMode" @click="editKeyword()" class="fa-solid fa-pencil"></i>
              <i v-if="editMode" @click="deleteKeyword()" class="fa-solid fa-trash-can"></i>
            </span>
        </div>
    </div>
</template>