<template>
<!--
  // kept in to outline use of the router for use when looking back during future projects.
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link :to="{ name : 'login'}">Login</router-link>|
    <router-link :to="{ name : 'dashboard'}">Dashboard</router-link>|
    <router-link :to="{ name : 'logout'}">Logout</router-link>|
    <router-link :to="{ name : 'booking'}">Booking Form</router-link>
  </nav>-->
  <router-view/>

</template>

<script >
/* eslint-disable */

import SocketioService from './services/socketio.service.js';
//import Controlss from './ControlsPanel.vue'

export default {

  name: 'App',
  components: {
    //Controlss

  },
  created() {
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    SocketioService.disconnect();
    
  },
  mounted(){
    
    //code kept in commented for loading the WEBGL three canvas which stopped working after adding a router to the project.
    //ThreeSceneService.createThreeScene( document.querySelector('canvas.webgl') );
  }
 
}


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.0.2/css/bootstrap.min.css');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css');

body{
  padding:0;
  margin:0;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

body .lightMode{
  
}

:root {
  --bah-primary: #01728e;
  --bah-secondary: #ebebeb;
  
  --bah-primary-text: #ffffff;
  --bah-secondary-text: #000000;
  
  --bah-primary-hover: #025d73;
  --bah-secondary-hover: #d8d5d5;

  /* Text Size */
  --ts-sml: calc(10px + (10 - 8) * ((100vw - 300px) / (1600 - 300)));
  --ts-reg: calc(20px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  --ts-med: calc(27px + (27 - 20) * ((100vw - 300px) / (1600 - 300)));
  --ts-large: calc(35px + (35 - 20) * ((100vw - 300px) / (1600 - 300)));
  --ts-xlarge: calc(60px + (60 - 20) * ((100vw - 300px) / (1600 - 300)));

  /* Text Colors */
  --text-light: #ffffff;
  --text-dark:#000000;
  --text-dgrey:#464646;
  --text-lgrey:#cacaca;


  /* Shadows */
  --shadow: 0px 1px 15px 0px rgba(0,0,0,.1);/*0px 9px 28px 0px rgba(0,0,0,0.1); */
  --shadow-btn: 0px 0px 30px -8px rgba(0,0,0,0.33);
  --shadow-btn-2: 0px 4px 5px rgba(0, 0, 0, 0.07), 12px 0px 25px rgba(0, 0, 0, 0.05);

  --bg-primary: #ffffff;
  --bg-secondary: #2e2e2e;
  --bg-offcolor: #578d8e;/*#f3f3f3;*/


}

.navbar-inline{
      display: inline-flex;

}

.navbar-inline a{
      color:var(--bah-primary);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.center-obj{
  justify-content:center;
  align-items:center;
  border-radius:50%;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

[class*="center-content"]{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
    
}

[class$="-column"]{
  flex-direction: column;
}

[class$="-secondary"]{
  background-color: var(--bah-secondary);
  border-color: var(--bah-secondary);
  color: var(--bah-secondary-text);
}

[class$="-primary"]{
  background-color: var(--bah-primary);
  border-color: var(--bah-primary);
  color: var(--bah-primary-text);
}

[class$="-primary-hover"]:hover{
  color: var(--bah-primary-hover);
}

.vr-btn-secondary:hover{
  background-color: var(--bah-secondary-hover);
  color: var(--bah-secondary-text);
  
}

.vr-btn-primary:hover{
  background-color: var(--bah-primary-hover);
  color:var(--bah-primary-text);
  
}

[class*="vr-btn"]{
    transition: 0.3s;
    border-radius: 10px;
    padding: 6px 12px 6px 12px;
    font-size: var(--text-size-reg);
    font-weight: bold;
    border: 2px solid var(--text-1);
    margin:5px;
}

.card{
  padding:0;
}

#page-content{
  padding-top: 35px;
  min-height: calc(100vh - 1.0rem - 40px);
}


/* ----- RESPONSIVE OPTIONS MUST STAY AT BOTTOM ----- */

/* SM size and above unless over ridden in bigger sizes */
@media (min-width: 576px) { /* sm size */

}

/* MD size and above unless over ridden in bigger sizes */
@media (min-width: 768px) {

}

/* LG size and above unless over ridden in bigger sizes */
@media (min-width: 992px) { 
  

}

 /* XL size and above */
@media (min-width: 1200px) {
  
}

</style>
