<script setup>
/* eslint-disable */
import { useVueFlow, Position } from '@braks/vue-flow';
import PostService from "../../services/PostService";

const flowKey = 'example-flow';

const {
  nodes,
  addNodes,
  setNodes,
  setEdges,
  dimensions,
  setTransform,
  toObject,
  updateEdge,
  onPaneReady,
  fitView,
  project,
} = useVueFlow();


onPaneReady(({ fitView }) => {
  fitView()
})

const position = project({ x: dimensions.value.width / 2, y: dimensions.value.height / 2});



const onSave = () => {
  localStorage.setItem(flowKey, JSON.stringify(toObject()));//instance.value?.
  console.log(localStorage)
};

const onRestore = () => {
  const flow = JSON.parse(localStorage.getItem(flowKey));

  if (flow) {
    const [x = 0, y = 0] = flow.position;
    setNodes(flow.nodes);
    setEdges(flow.edges);
    setTransform({ x, y, zoom: flow.zoom || 0 });//instance.value?.
  }
};

const onAdd = () => {
  var answerQantity = 3;
  var questionHeight = 47 + 40 * answerQantity;

  const id = nodes.value.length + 1;
  const newNode = {
    id: `question_node-${id}`,
    type: 'questionnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position: position, /*{
      x: dimensions.value.width / 2,
      y: dimensions.value.height / 2,
    },*/
    style: {
      backgroundColor: 'rgb(232 232 232)',
      width: '200px',
      height: `${questionHeight}px`,
    },
    class: 'light',
    data: { questionID: id, questionText: 'example question' },
  };
  addNodes([newNode]);

  for (let i = 0; i < answerQantity; i++) {
    console.log('adding answers');

    let y = 40 + 40 * i;
    const AnswerChildNode = {
      id: `answer_node-${i}-to-${id}`,
      type: 'answernode',
      //label: `Node ${id}`,
      targetHandle: Position.Left, // or Bottom, Left, Right,
      sourceHandle: Position.Left,
      position: {
        x: 25,
        y: y,
      },
      extent: 'parent',
      parentNode: `question_node-${id}`,
      class: 'light',
      expandParent: true,
      draggable: false,
      selectable: false,
      data: { answerID: i + 1, answerText: 'example answer' },
    };
    addNodes([AnswerChildNode]);
  }
  /*
  const newChildNode2 = {
    id: `answer_node-2-of-${id}`,
    type: 'childnode',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position: {
      x: 25,
      y: 80,
    },
    parentNode: `question_node-${id}`,
    extent: 'parent',
    class: 'light',
    expandParent: false,
    draggable: false,
  };
*/
  //, newChildNode, newChildNode2
};

const onEndAdd = (yPosition,xPosition) => {

  if(yPosition == 0 && xPosition == 0){
  
  const id = nodes.value.length + 1;
  const newNode = {
    id: `End-Session-Node-${id}`,
    type: 'endsessionnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  project({ x: (dimensions.value.width / 2 ) - 150, y: (dimensions.value.height / 2) - 20}),
    
  };
  addNodes([newNode]);
  }else{
  const id = nodes.value.length + 1;
  const newNode = {
    id: `End-Session-Node-${id}`,
    type: 'endsessionnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  {
      x: xPosition,
      y: yPosition,
    },
    
  };
  addNodes([newNode]);
  }

};

const onInvAdd = () => {
  const id = nodes.value.length + 1;
  const newNode = {
    id: `Intervention-Node-${id}`,
    type: 'interventionnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position: position, /*{
      x: dimensions.value.width / 2,
      y: dimensions.value.height / 2,
    },*/
    data: { interventionText: 'Worry Tree Intervention', interventionDescription: 'Intervention description..........' },
  };
  addNodes([newNode]);
};

const onTreeAdd = () => {
  const id = nodes.value.length + 1;
  const newNode = {
    id: `TreeLink-Node-${id}`,
    type: 'treelinknode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position: position, /*{
      x: dimensions.value.width / 2,
      y: dimensions.value.height / 2,
    },*/
    data: { treelinkText: 'Another Tree', treelinkDescription: 'Links to a different tree' },
  };
  addNodes([newNode]);
};

const onMessageAdd = async (yPosition,xPosition) => {


  const response = await PostService.insertMessage("");

  console.log('new id added for message: ' + response.data.body);

  const id = response.data.body;
  const newNode = {
    id: `Message-Node-${id}`,
    type: 'messagenode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  {
      x: xPosition,
      y: yPosition,
    },
    
    data: { id:id, messageText: ''},
    dragHandle : '.draghandle', 
  };
  addNodes([newNode]);
};

const onResourceAdd = async (yPosition,xPosition) => {

  const response = await PostService.insert("resourcenodes", ['resourceid'], [null]);
  //const response = await PostService.insertResource("");

  console.log('new id added for Resource: ' + response.data.body);

  const id = response.data.body;
  const newNode = {
    id: `Resource-Node-${id}`,
    type: 'resourcenode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  {
      x: xPosition,
      y: yPosition,
    },
    
    data: { id:id, resourceSelected: '1'},
    dragHandle : '.draghandle', 
  };
  addNodes([newNode]);
};


    
const onCommentAdd = async (yPosition,xPosition) => {

  const response = await PostService.insertComment("");

  console.log('new id added for message: ' + response.data.body);

  const id = response.data.body;
  const newNode = {
    id: `Comment-Node-${id}`,
    type: 'commentnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  {
      x: xPosition,
      y: yPosition,
    },
    dragHandle : '.draghandle', 
    data: { id: id, commentText: ''},
  };
  addNodes([newNode]);
};

const onConditionAdd = (yPosition,xPosition) => {

  const id = nodes.value.length + 1;
  const newNode = {
    id: `IfCondition-Node-${id}`,
    type: 'ifconditionnode',
    label: 'parent',
    //label: `Node ${id}`,
    targetHandle: Position.Left, // or Bottom, Left, Right,
    sourceHandle: Position.Left,
    position:  {
      x: xPosition,
      y: yPosition,
    },
    data: { id : id },
  };
  addNodes([newNode]);
};


function ToggleMenu() {
  console.log('menu location', this.xPosition);
}

/*
var emitter = require('tiny-emitter/instance');
 
emitter.on('addWeight', function (arg1) {
  console.log('emit recieved by additionalControls width' + arg1);
});
*/
/*
 consol.log('emit recieved by additionalControls')
});*/


</script>
<script>
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  // type inference enabled
  props: {
    xPosition: Number,
    yPosition: Number,
  },
  computed: {
    style() {
      return 'top: ' + this.yPosition + 'px; left: ' + this.xPosition + 'px;';
    },
  },
  data() {
    return {
      toggle: false,
    };
  },
  watch: {
    // It listens to the change in prop name
    xPosition: function () {
      console.log('x change');
      this.toggle = true; // print out when the name changes
    },
    yPosition: function () {
      console.log('y change');
      this.toggle = true; // print out when the name changes
    },
  },
  mounted() {
    //this.xPosition = 0;
    //this.yPosition = 100;
    console.log('ypos: ' + this.yPosition);
  },
  methods: {
    ToggleMenu() {
      console.log('menu location', this.xPosition);
    },
    CloseMenu() {
      this.toggle = false;
    },
    onQuestionPressed() {
      this.onAdd();
      this.CloseMenu();
    },
    onEndPressed() {
      this.onEndAdd();
      this.CloseMenu();
    },
    openQuestionPanel () {
      this.$emit('questionToggle', true);
      this.CloseMenu();
    },
    openInterventionPanel () {
      this.$emit('interventionToggle', true);
      this.CloseMenu();
    },
    openTreesPanel () {
      this.$emit('treesToggle', true);
      this.CloseMenu();
    },
    changeRightClick(){
      this.$emit('debugRightClick', true);
      this.CloseMenu();
    },

  },
});
</script>
<template>
  <div
    v-if="toggle"
    :style="style"
    class="card flow-menu"
    v-click-outside="CloseMenu"
  >
    <div class="card-header noselect">Add Node</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item noselect" @click="openQuestionPanel">
        Question
      </li>
      <li class="list-group-item noselect" @click="openInterventionPanel">Intervention</li>
      <li class="list-group-item noselect" @click="openTreesPanel">Tree Link</li>
      <li class="list-group-item noselect" @click="CloseMenu(),onEndAdd(yPosition,xPosition)">
        End Session
      </li>
      <li class="list-group-item noselect" @click="CloseMenu(),onCommentAdd(yPosition,xPosition)">
        Comment
      </li>
      <li class="list-group-item noselect" @click="CloseMenu(),onMessageAdd(yPosition,xPosition)">
        Session Message
      </li>
      <li class="list-group-item noselect" @click="CloseMenu(),onConditionAdd(yPosition,xPosition)">
        If Condition
      </li>
      <li class="list-group-item noselect" @click="CloseMenu(),onResourceAdd(yPosition,xPosition)">
        Unlock Resource
      </li>
      
    </ul>
  </div>

  <div class="save__controls">
    <button class="flow-buttons active-tooltip" @click="onSave">Local store<div class="bottom-tooltip">Currently saves locally upon click, however it can be used to save each time something is done to work like Undo / redo </div></button>
    <button class="flow-buttons active-tooltip" @click="onRestore">Local restore<div class="bottom-tooltip">Currently restores the last local save, however it can be used to restore back to different local saves like an Undo / redo </div></button>
    <button class="flow-buttons active-tooltip" @click="openQuestionPanel" style="position: relative;"><i class="fa-solid fa-comment"></i><div class="bottom-tooltip">Add a question to the flow</div></button>
    <button class="flow-buttons active-tooltip" @click="onEndAdd(0, 0)" style="position: relative;"><i class="fa-solid fa-comment-slash"></i><div class="bottom-tooltip">Add a end session node to the flow</div></button>
    <button class="flow-buttons active-tooltip" @click="openTreesPanel" style="position: relative;"><i class="fa-solid fa-diagram-project"></i><div class="bottom-tooltip">Add a link to another flow tree</div></button>
    <button class="flow-buttons active-tooltip" @click="openInterventionPanel" style="position: relative;"><i class="fa-solid fa-message"></i><div class="bottom-tooltip">Add an intervention to the flow</div></button>
    <button class="flow-buttons active-tooltip" @click="changeRightClick" style="position: relative;"><i class="fa-solid fa-bug"></i><div class="bottom-tooltip">Debug option for development purposes only</div></button>
  </div>
</template>

<style>
.save__controls {
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 20px;
}

.flow-menu .list-group-item:hover {
  background-color: #e5e5e5;
}
</style>
