/* eslint-disable */
import { defineStore } from 'pinia';
import { isNode, updateEdge } from '@braks/vue-flow';
import PostService from "../../services/PostService";
import eventBus from '../../event-bus.js';


const useStore = defineStore('elementsStore', {
  state() {
    return {
      elements: [
        {
          id: 'start-session-node',
          type: 'startsessionnode',
          label: 'Node 1',
          position: { x: 250, y: 5 },
          class: 'light',
        },
      ],
      title: null,
      newtitle: null,
      description: null,
      treeID: 0,
      weightArray: [],
      loadedWeightArray: [],
      messagesToRemove: [],
      resourcesToRemove: [],
      commentsToRemove: [],
      dupLinksMap: null,
    };
  },
  mounted() {
    
   
/*
    function makeWeight(qID, wID, weight) {
      return {
        QuestionID: qID,
        WeightID: wID,
        Weight: weight,
      };
    }
*/
  },
  actions: {
    loadEventBuses() {
      console.log("CALLED ON LOAD OF SCRIPT");
      //TODO: WEIGHTS ON UPDATE OF WEIGHT SAVE IN ARRAY READY TO BE STORED IN DATABASE.
  
      eventBus.on('UpdateWeight', (identity, weightValue) => {
        console.log("update called with values: " + identity[0] + " : " + identity[1] + " weightof: " + weightValue);
        this.weightArray.find((element, index) => { 
              console.log("Searching: " + this.weightArray[index].QuestionID + " : " + this.weightArray[index].WeightID)
          if(this.weightArray[index].QuestionID == identity[0] && this.weightArray[index].WeightID == identity[1]){
              console.log("Found weight to update with value: " + weightValue)
              
              this.weightArray[index].Weight = weightValue;
              console.log(this.weightArray);
          }
  
        })
        //(qID => weightArray.QuestionID === questionID &&  weightArray.AnswerID === answerID);
        //weightArray.push(makeWeight(questionID, weightID, weight));
      });
  
      eventBus.on('AddNewWeight', (identity, weight) => {
        this.weightArray.push(this.makeWeight(identity[0], identity[1], weight));
        console.log("new item added to weight array");
        console.log(this.weightArray);
      });
    },
    makeWeight(qID, wID, weight) {
      return {
        QuestionID: qID,
        WeightID: wID,
        Weight: weight,
      };
    },

    async log(dupLinkMap) {
      //console.log('stored elements', this.elements);

      let saveData = JSON.stringify(this.elements); 
      let weightData =  JSON.stringify(this.weightArray);
      let duplinksData =  JSON.stringify(dupLinkMap);

      console.log("saving dup link data");
      console.log(duplinksData);
      //console.log(this.weightArray);
      //console.log(weightData);


      const response = await PostService.saveVueFlow( this.treeID, this.title, this.newtitle, this.description, saveData, false, weightData, null, duplinksData);

      this.messagesToRemove.forEach(async (nodeId) => {
        console.log("remove the following ID from messages: " + nodeId);
        const response = await PostService.delete(nodeId, "messages");
      });
      this.commentsToRemove.forEach(async (nodeId) => {
        console.log("remove the following ID from comments: " + nodeId);
        const response = await PostService.delete(nodeId, "comments");

      });
      this.resourcesToRemove.forEach(async (nodeId) => {
        console.log("remove the following ID from resources: " + nodeId);
        const response = await PostService.delete(nodeId, "resourcenodes");

      });

      console.log(response.data.message);

      if (response.data.message === 'error'){
        console.log(response.data.body);
      }
    },
    async load(treeData){

      //console.log("loading tree...");
      //const response = await PostService.loadVueFlow(1);

      //console.log(response.data.body[0]["SaveData"]);
      this.loadEventBuses();
      this.title = treeData["title"];
      this.newtitle = treeData["title"];
      this.description = treeData["description"];
      this.treeID = treeData["id"];
      this.loadedWeightArray = treeData["weights"];
      this.dupLinksMap = treeData["duplicatelinks"];

      if(treeData["savedata"] != null){
        this.elements = JSON.parse(treeData["savedata"]);
       
      }else{
        this.elements = [
          {
            id: 'start-session-node',
            type: 'startsessionnode',
            label: 'Node 1',
            position: { x: 250, y: 5 },
            class: 'light',
          },
        ];
      }
      //const [x = 0, y = 0] = this.elements.position;
      //setNodes(this.elements.nodes);
      //setEdges(this.elements.edges);
      //setTransform({ x, y, zoom: this.elements.zoom || 0 })

      //await this.$nextTick()
      

    },
    populateWeights(){


      if(this.loadedWeightArray != null){
        console.log("weight not null");
        this.weightArray = JSON.parse(this.loadedWeightArray);
        this.weightArray.forEach((element) => {
          //const node = getNode.value('weighted_node-' + element["WeightID"] + '-to-' + element["QuestionID"]);
          //console.log("found node:" + node);
          //if (node) {
          //   node.data.Weight = element["Weight"];
          //}

          //console.log('emit event name: Weight-Q' + element["QuestionID"] + '-A' + element["WeightID"] + ' with weight:' + element["Weight"]);
          eventBus.emit('Weight-Q' + element["QuestionID"] + '-A' + element["WeightID"], element["Weight"]);
        }); 
        
      }else{
        this.weightArray = [];
      }
    },
    populateLinks(){
      if(this.dupLinksMap != null){

        this.dupLinksMap.forEach((key, value) => {
          //console.log(value, key); // 👉️ Chile country, 30 age
          eventBus.emit(key + "-dupUpdate", dupLinksMap.get(key).get("duplicates") );
          value.get("selectedlink").forEach((key2, value2) => {
            console.log("calling dupload for q: " + key + " duplink: " + value2);
            eventBus.emit(key + "-dupLoad-" + key2 , value2 );
          });
          //eventBus.emit(key + "-dupLoad-" , dupLinksMap.get(key).get("selectedlink") );

        });
        //eventBus.on(props.data.questionID + "-dupUpdate", async (updatedDuplicate) => {
      }
    },
    onEdgeUpdateStart(edge) {
      console.log('start update', edge);
    },
    onEdgeUpdateEnd(edge) {
      console.log('end update', edge);
    },
    onEdgeUpdate({ edge, connection }) {
      this.elements = updateEdge(edge, connection, this.elements);
    },
  },
});

export default useStore;
