<script setup>
/* eslint-disable */


//var treelinks ='[{"id":1,"Description":"Concerned Keyword Tree","Title":"Concerned Keyword Tree"},{"id":2,"Description":"Worry Tree Session","Title":"Worry Tree Session"}]';

//var treelinkData = JSON.parse(treelinks);
//console.log(treelinkData[0].id);

/*
const onDragStart = (event, nodeType, treelinkID) => {
  console.log('drag started! ');
  if (event.dataTransfer) {
    event.dataTransfer.setData('application/vueflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('treelinkID',this.treelinkData[treelinkID].ID);
    event.dataTransfer.setData('treelinkDesc',this.treelinkData[treelinkID].Description);
    event.dataTransfer.setData('treelinkTitle',this.treelinkData[treelinkID].Title);

  }
};
*/
</script>
<script>

import { defineComponent } from 'vue';
import PostService from "../../services/PostService";

export default defineComponent({
  props: { 
  },
  data() {
    return {
      toggle: false,
      treelinkData: [],
    };
  },
  async created() {
  
    var databaseString = await PostService.selectAll("trees");
    console.log('Tree Bar Loading in');
    console.log(databaseString.data.body);
    this.treelinkData = databaseString.data.body;
    console.log(this.treelinkData[0].title);

  },
  methods: {
    closePanel() {
      this.$emit('closePanel', true);
    },
     onDragStart (event, nodeType, treelinkID) {
      console.log('drag started! ');
      if (event.dataTransfer) {
        event.dataTransfer.setData('application/vueflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('treelinkID',this.treelinkData[treelinkID].id);
        event.dataTransfer.setData('treelinkDesc',this.treelinkData[treelinkID].description);
        event.dataTransfer.setData('treelinkTitle',this.treelinkData[treelinkID].title);

      }

    },
  },
});
</script>

<template>
  <aside>
    <div class="closeBtn"  @click="closePanel">Close</div><!-- @click="closePanel" -->
    <div v-for="(singleT, index) in treelinkData" :key="index">
    <div class="nodes">
      <div
        class="vue-flow__node-select"
        :draggable="true"
        @dragstart="onDragStart($event, 'treelinknode', index)">
        {{singleT.title}}
      </div>
    </div>
    </div>
  </aside>
</template>

<style>
.closeBtn{
  color:black;
}

</style>