<template>
<nav class="navbar navbar-expand-lg navbar-light sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" @click="this.$router.push({ name: 'home' })">VR HUB</a>
    <span class="navbar-inline" v-if="authStatus">
      <a class="nav-link bah-primary-hover" href="#" @click="this.$router.push({ name: 'dashboard' })" >Dashboard</a>
      <a class="nav-link bah-primary-hover" href="#" @click="this.$router.push({ name: 'logout' })">Logout</a>
    </span>
    <span class="navbar-inline" v-else>
      <a class="nav-link bah-primary-hover" href="#" @click="this.$router.push({ name: 'login' })">Login</a>
      <a class="nav-link bah-primary-hover" href="#" @click="this.$router.push({ name: 'booking' })" >Booking Form </a>
    </span>
  </div>
</nav>
</template>

<script>
import SocketioService from '../services/socketio.service.js';

/* eslint-disable */ 
export default {
  name: 'MenuComponent',
  props: {

  },
  data(){
      return {
        authStatus: false
      }
    },
  created(){

    this.authStatus = SocketioService.getAuthStatus();
      
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .navbar-light {
    background-color:white;
    box-shadow:var(--shadow);
  }
</style>
