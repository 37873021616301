
import MenuComponent from '../components/MenuComponent.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'HomeView',
  components: {
    MenuComponent,
  },
  mounted(){
   console.log('URL is' + process.env.NODE_ENV  + process.env.PORT);
  },
})
export default class HomeView extends Vue {}
