<template>
<div class="container">
  <h2 class="panel-title">Environments</h2>
  <hr/>
  <p>View stats, Turn Scenes on and off dependant on statistic improvements.</p>
  <div class="container">
    

    <div class="row">
      
      <div class="col-sm-3">
        <div class="card mb-3">
          <img src="../../assets/SceneImages/Desert.png" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text">Scene Stats:</p>
            <p class="card-text">No stats Available</p>

          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
            <label class="form-check-label" for="flexSwitchCheckDefault">On / off</label>
          </div>

          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card mb-3" >
          <img src="../../assets/SceneImages/FrozenLake.png" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text">Scene Stats:</p>
            <p class="card-text">No stats Available</p>

            <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
            <label class="form-check-label" for="flexSwitchCheckDefault">On / off</label>
          </div>
          </div>
          
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card mb-3" >
          <img src="../../assets/SceneImages/Lake.png" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text">Scene Stats:</p>
            <p class="card-text">No stats Available</p>
            <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
            <label class="form-check-label" for="flexSwitchCheckDefault">On / off</label>
          </div>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="card mb-3" >
            <img src="../../assets/SceneImages/MagicalForest.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">Scene Stats:</p>
              <p class="card-text">No stats Available</p>
              <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
              <label class="form-check-label" for="flexSwitchCheckDefault">On / off</label>
            </div>
            </div>
          </div>
      </div>

      <div class="col-sm-3">
        <div class="card mb-3" >
          <img src="../../assets/SceneImages/NightBeach.png" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text">Scene Stats:</p>
            <p class="card-text">No stats Available</p>
            <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked />
            <label class="form-check-label" for="flexSwitchCheckDefault">On / off</label>
          </div>
          </div>
        </div>
      </div>

      

    </div>

  </div>

</div>
</template>

<script>



//import SocketioService from '../../services/socketio.service.js';

  export default {
    name: "EnvironmentsView",
    components: {
      
    },
    data () {
      //var socket = SocketioService.getActiveSocket();
      //socket.emit('GetQanA');
 
      /*socket.on("QanAdata", (arg) => {
        console.log(arg);
      });*/
        
      return {
        //loginCode: '',
        //highestIndex: 0,
        //addQuestion: false,
      }
    },
    mounted(){
      //this.loginCode = SocketioService.getLoginCode();

      /*
      fetch('http://localhost:3000/questions')
        .then((res) => res.json())
        .then(data => this.qanaData = data)
        .catch(err => console.log(err.message))
        console.log(this.qanaData);
      */
        
    },
    async created() {

      /*
      // GET request using fetch with async/await
      const response = await fetch("http://localhost:3000/questions");
      this.qanaData = await response.json();

      for(var data of this.qanaData ){
        if(Number(data.QuestionIndex) > Number(this.highestIndex )){
          this.highestIndex = Number(data.QuestionIndex);
        }
      }

      console.log('the next question id is:' + this.highestIndex);
      */
      //console.log(JSON.parse(this.qanaData[0].Answers).Answers);
      //console.log(this.qanaData[0].Question);
      //this.totalVuePackages = data.total;
    },
    methods:{/*
      updateQuestions(value){
        console.log(value.q);
        console.log(value.a);
        console.log('{"Answers" :' + JSON.stringify(value.a) + '}');
        //console.log(JSON.parse(value.a));
        console.log(value.s);
        console.log(value.id);
        console.log("Question ID after emit is:" + value.id);
        this.qanaData[value.id].Question = value.q;
        this.qanaData[value.id].Answers = '{"Answers" :' + JSON.stringify(value.a) + '}';
        this.qanaData[value.id].Session_Allocation = value.s;
        console.log(this.qanaData[value.id].Answers);
        //JSON.parse(qana.Answers).Answers
        //JSON.stringify
        const responce = QuestionService.updateQuestion(this.qanaData[value.id])
        console.log(responce);
      },
      newQuestion(value){
        console.log("New Question saved" + value.q);
        const newQ = JSON.parse('[{"id": "'+ value.id +'", "QuestionIndex":"' + value.r + '", "Question": "' + value.q + '", "Answers":"","Type":null,"Session_Allocation":"' + value.allo + '"}]');
        newQ[0].Answers = '{"Answers" :' + JSON.stringify(value.a) + '}';
        console.log(newQ);
        const responce = QuestionService.insertQuestion(newQ[0])
        console.log(responce);
        this.$router.go();
        //{"Answers" : []}
      }*/
    },/*
    created() {

      
        fetch('http://localhost:3000/questions').then(response => {
            this.qanaData = response.data
            
        }, error =>{
          console.log("Error: " + error)
        });
        console.log(JSON.parse(JSON.stringify(this.qanaData)))
    },
    */
    /*props: {
      msg: String
    }*/
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20px; }
  .toggle.ios .toggle-handle { border-radius: 20px; }
  .toggle.android { border-radius: 0px;}
  .toggle.android .toggle-handle { border-radius: 0px; }

  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
      outline-width: 0 !important;
      border-bottom: 2px solid black;
      outline: none;
  }
  .add-question-btn{
    width:auto;
  }
  .add-question-row{
    justify-content: center;
  }

</style>
