import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import LogoutView from '../views/LogoutView.vue'
import Error404View from '../views/404View.vue'
import BookingFromView from '../views/BookingFromView.vue'
import SocketioService from '../services/socketio.service.js'

//import { app } from '../main'

import { useCookies } from "vue3-cookies";


// router used for navigating users around the website. dependant on buttons pressed or url within the URL bar.

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  
  },
  {
    path: '/booking',
    name: 'booking',
    component: BookingFromView,
    meta: { requiresAuth: false }
   
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: { requiresAuth: true }
   
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:catchAll(.*)',
    name: '404 Error',
    component: Error404View
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


var sessionLoaded = false;

router.beforeEach(async (to,from,next)=>{
  
  if(!sessionLoaded){
    const { cookies } = useCookies();
    let x = cookies.get('sessionCookie');
        
    console.log('cookie in ROUTER: ' + x)
    console.log(cookies);
    if(await SocketioService.setupSocket(x)){
      console.log('socket has a session');
      console.log(to.name);
      if(to.name = 'login'){
        router.replace('/dashboard');
      }else{
      next();
      }
      //TODO add an auth for if the user is admin or standard user.
    }else{
      console.log('socket has no session');
      if (to.matched.some(record => record.meta.requiresAuth)) {
        router.replace('/login');
      }
      next();
    }
    sessionLoaded = true;
  }else{
    next();
  }
  // check if the route to be accessed requires authorizaton
// if (to.matched.some(record => record.meta.requiresAuth)) {

  //const { cookies } = useCookies();
  //let x = cookies.get('sessionCookie');

  // Check if user is Authenticated
  //const sessionID = $cookies.get("username");
  //let x = VueCookies.get("username");//document.cookie;
 // console.log("cookies are");
  //const cookie = app.config.globalProperties.$cookies.get("sessionCookie");
  //console.log(cookie);
  /*if(SocketioService.getAuthStatus()){
    // user is Authenticated - allow access
    
    console.log("go to next");
    //next();
  }
  else{
    console.log("go to login");
    // user is not authenticated - redirect to login
    //router.replace('/login');
  }
  */
//  next();
//}/
// Allow page to load 
//else{
  //let access_token = Vue.cookies.get('sessionCookie')
  //console.log("cookies are");
 // const { cookie } = app.config.globalProperties.$cookies.get("sessionCookie");
  //console.log(cookie);
  //next();
  //next();
//}


});
 

export default router;
