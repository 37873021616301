<script>
/* eslint-disable */

import PostService from "../../services/PostService";
import eventBus from '../../event-bus.js';
import { blockStatement } from "@babel/types";
//import SocketioService from '../../services/socketio.service.js';
import Keyword from "./Keyword.vue";
  export default {
    name: "KeywordsLibraryView",
    components: {
      Keyword,
    },
    data () {
      return {
        createModal: null,
        createModalBD: null,
        keywordData: [],
        ActiveKeyword: 0,
        newWeight:'',
        newKeyword:'',
      }
    },
    async created() {

      await this.loadKeywords();
    },
    mounted(){
      this.createModal = document.getElementById('keyword-Modal');
      this.createModalBD = document.getElementById('modal-backdrop');
        
    },
    methods: {
      async CreateNew(){
        //if (this.createTitle != ""){
          //var saveData = ""

          let columns = {
            keyword : "keyword",
            weight : "weight"
          }

          let values = [this.newKeyword, this.newWeight];

          var response = await PostService.insert("keywordlibrary", columns, values);
          console.log("create id: " + response.data.body);
          //this.ActiveKeyword = Object.keys(this.keywordData).length;

          await this.loadKeywords();

          //eventBus.emit('LoadFlowTree',  this.treelinkData[newID]);
          //this.$emit('closeLibrary');
          //this.openModal();
        //}else{
        //  console.log("tree title was blank");
          //Throw Error
        //}
          this.closeModal();

        //this.createDesc = "";
        //this.createTitle = "";
      },
      async openModal(){

        this.createModalBD = document.getElementById('modal-backdrop');
        this.createModal = document.getElementById('keyword-Modal');
        this.createModal.style.display = 'block';
        this.createModal.style.opacity = '1';
        this.createModalBD.style.display = 'block';
        this.createModalBD.style.opacity = '0.5';
        //this.questionActive = true;
        await this.$nextTick();
  
      },
      async closeModal(){
        this.createModalBD = document.getElementById('modal-backdrop');
        this.createModal = document.getElementById('keyword-Modal');
        this.createModal.style.display = 'none';
        this.createModal.style.opacity = '0';
        this.createModalBD.style.display = 'none';
        this.createModalBD.style.opacity = '0';
       // this.questionActive = false;
        await this.loadKeywords();
        await this.$nextTick();
        
      },
      async loadKeywords(){

          var databaseString = await PostService.selectAll("keywordlibrary");
          console.log("data body: ");
          console.log(databaseString.data.body);
          this.keywordData = databaseString.data.body;
          //var databaseString = await PostService.saveKeywordLibrary(inID, inKeyword, inWeight, isNew)
          //this.treelinkData = databaseString.data.body;
      },
      async updateKeyword(keyword, weight, id){
          console.log("update keyword data recieved: " + keyword + " : " + weight + " Where id = " + id);

          let columns = {
            keyword : "keyword",
            weight : "weight"
          }

          let values = [keyword, weight, id];

          var response = await PostService.update("keywordlibrary", columns, values);
          //console.log("create id: " + response.data.newID);
      },
      async deleteKeyword(id){
        console.log("deleting keyword with id: " + id);

        var response = await PostService.delete(id, "keywordslibrary");

        console.log("delete query response: " + response.data.success);
      },
      closeLibrary(){
        this.$emit('closeLibrary');
      }


      },
  };
</script>
<template>
<div class="keyword-lib-panel-bg"  @click="closeLibrary()"></div>
<div class="keyword-lib-panel">
  <div class="keyword-lib-contents">
    <span class="close-lib-btn" @click="closeLibrary()">Close</span>
    <h2>Keywords Library</h2>
    <hr/>
    <p>A library of keywords and phrases with weighted value.</p>

    <div class="container keyword-lib-main-contents">
      <div class="row">

        <div class="col-4 keyword-item keyword-add-btn" @click="openModal">
          <p>Add New</p>
        </div>

        <Keyword v-for="(singleK, index) in keywordData" 
          :key="index" 
          :keyword="singleK.keyword" 
          :weight="singleK.weight" 
          :index="index"
          :id="singleK.id"
          @delete="deleteKeyword(id)"
          @update="updateKeyword"
        />

      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="keyword-Modal" tabindex="-1" aria-labelledby="keyword-ModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="keyword-ModalLabel">Question</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="input-group">
          <span class="input-group-text">Keyword and Weight</span>
          <input type="text" aria-label="Keyword" placeholder="Keyword / Phrase" class="form-control" v-model="newKeyword">
          <input type="text" aria-label="Weight" plceholder="Weight" class="form-control" v-model="newWeight">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal()">Close</button>
        <button type="button" class="btn btn-primary"  @click="CreateNew()">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div id="modal-backdrop" class="modal-backdrop fade show" style="display: none;" @click="closeModal()"></div>

</template>


<style>


.keyword-lib-main-contents{
  overflow-y: auto;
  max-height: calc(80vh - 100px - 40px); /* height of div - height of header section - padding of parent */
}

.keyword-lib-panel{
  background-color:#ffffff;
  border-radius:10px;
  border: solid 1px #f2f2f2;
  width:80vw;
  height:80vh;
  position:absolute;
  z-index:1010;
  top:10vh;
  left:10vw;

}

.keyword-lib-panel .container{
  overflow-y: auto;
}


.keyword-lib-panel-bg{
  background-color:rgba(220,220,220,0.5);
  width:100vw;
  height:100vh;
  position:absolute;
  z-index:1005;
  top:0;
  left:0;
}

.keyword-lib-contents{
  text-align:left;
  padding:20px 30px;
}



.keyword-item{
  display:flex;
  align-items:center;
  padding:5px 20px;
  justify-content: space-between;
}

.keyword-item p {
  margin:0;
}

.keyword-item input{
  border:0px;
  border-bottom:2px solid #f5f5f5;
}

.keyword-item:nth-child(3n - 1) { 
  border-left:1px solid #000000;
  border-right:1px solid #000000;
}

.keyword-item input:focus {
  outline-width: 0 !important;
  border-bottom: 2px solid black;
  outline: none;
}

.keyword-options {
  height: 100%;
  display: flex;
  align-items: center;
}

.keyword-options i{
  padding-left:10px;
  font-size: 14px;
}

.fa-check{
  font-size:17px !important;
}

.keyword-add-btn{
    text-align: center;
    justify-content: center;
    background-color: #ecf9f9;
}


.close-lib-btn{
  position:absolute;
  top:10px;
  right:10px;
}

</style>
