//import { values } from 'core-js/core/array';
//import { ConnectionClosedEvent } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/ConnectionEvents';
import { io } from 'socket.io-client';

import eventBus from '../event-bus.js';
import { computed, onMounted, nextTick, ref,watch,reactive,isReactive} from 'vue';

//import router from '@/router/index.js';

// eslint-disable-next-line
var authStatus = false;
var permissions = null;
var loginCode = null;
var questionID, treeID;
var sessionLoaded = false;
var answerType = 0;
const key = 'SessionID';
var lastAnswer = '', answerText= '';

var socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);

/*
onMounted(() =>{

  //socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);

  console.log('listening for socket communication front end');
  socket.on("newSessionMessage", (result) => {
    console.log('Session recieved a new message');
    eventBus.emit('newSessionMessage', result);
  });  
 
  socket.on("ActiveCoachQuestion", (result) => {
    console.log("Active Coach question");
    console.log( result);
    eventBus.emit('ActiveCoachIncoming' , result[2]);
  });   
});
*/

class SocketioService {
  constructor() {
    /*
    //socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
    console.log('listening for answers');
    socket.on("newSessionMessage", (result) => {
      console.log('Session recieved a new message');
      eventBus.emit('newSessionMessage', result);
    });  
   
    socket.on("ActiveCoachQuestion", (result) => {
      console.log("Active Coach question");
      console.log( result);
      eventBus.emit('ActiveCoachIncoming' , result[2]);
    });   */
  }



  async setupSocket(session){
    return new Promise(async (res, error) => {

    //socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
    //const session = localStorage.getItem(key);
      console.log("sessionID recieved on socket emit: " +  session)
    await socket.emit('sessionExists', session, result => {
      /*
      console.log("session exits result: " + result);
      if (session) {
        console.log('session EXISTS: ' + session)
        authStatus = result;
        res(authStatus);
        //this.$router.push({ name: 'dashboard' })

      }else{
        localStorage.setItem(key, result);
        authStatus = false;
        res(authStatus);
        //this.$router.push({ name: 'dashboard' })

      }*/

      
      res(result);
    })
  });
  }
 

  async setupSocketConnection() {
   
    //socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);
     //testing use of sockets
   // socket.emit('my message', 'Hello there from Vue.');
    
    

  }


  disconnect() {
    if (socket) {
        socket.disconnect();
    }
  }

  // used to set whether the user is an admin or standard user.
  setAuth(value, username,id){
    authStatus = value;
    console.log("auth updated: " + authStatus)
    socket.emit('loginClient', username, id, result => {
      console.log("login client: " + result);
    })
  }

  logOut(){
    authStatus = false;
    console.log("updated auth");
    socket.emit('logOut');
    localStorage.removeItem(key);
  }

 getAuthStatus(){

      return authStatus;
  

    /*socket.emit('checkLoggedIn', result => {
      console.log("check login callback: " + result);
      authStatus = result;
    })*/
  }

  //sets the quick login code
  setLoginCode(newCode){
    loginCode = newCode;
    
  }

  // used to get the current login code for the frontend
  getLoginCode(){
    return loginCode;
  }

  testUnrealLogin () {
    // 20 = start node, 29 = tree ID
    let input = {
      loginWord : 'cabbage',
      questionID : 20,
      treeID : 29
    }
    socket.emit('login', input, result => {
      console.log("login: " + result[0] + ", " + result[2]);
      questionID = result[1];
      console.log(result);
    })
  }

  testUnrealAnswer () {
    let tempAnswerJson = ["1", questionID, "hello vr coach"];
    JSON.stringify(tempAnswerJson);
    socket.emit('answer', tempAnswerJson, result => {
      console.log("Callback from answer: " + result[0] + " " + result[1] + " " + result[2]);
      questionID = result[1];
    })
  }

  loadQuestionnaire (sessionTreeID) {
    //20 stands for the start node
    return new Promise(async (res, error) => {
    socket.emit('loadQuestionnaire', 'cabbage', 20, sessionTreeID, result => {
      if(result[0] != "success"){
        error("No session data found.");
      }else{
        console.log("login: " + result[0] + ", " + result[2]);
        questionID = result[1];
        treeID = sessionTreeID;

        /*
          2 = welcome message
          3 = audio
        */
        res(result);
      }
    })
  });
  }

  closeQuestionnaire(){
    return new Promise(async (res, error) => {
      socket.emit('endQuestionnaireSession', result => {
        if(result[0] != "success"){
          error("No session data found.");
          res(result);
        }else{
          console.log("Questionnaire Session saved");
          res(result);
        }
      })
    });
    
  }


  sendQuestionnaireAnswer (answerID, answerText) {
    return new Promise(async (res, error) => {
      lastAnswer = answerID;
      answerText = answerText;
      console.log("answertype is: " + answerType);
      let tempAnswerJson = [answerID, questionID, answerType, answerText ,treeID];
      JSON.stringify(tempAnswerJson);
      socket.emit('answer', tempAnswerJson, result => {
        if(result[0] != "success" && result[0] != "SessionTakenOver"){
          error("No session data found.");
        }else{
          
            answerType = result[4];
            questionID = result[1];
            /*
            2 = question
            3 = audio
            4 = type
            5 = answers
            */
            res(result);

        }
        
      })
    });
  }


  translateFlowJsonPass() {
    socket.emit('translate')
  }


  async sessionLoadRequest(sessionID) {
    return new Promise(async (res, error) => {
      socket.emit('SessionLoadReq', sessionID, result => {
        
        if(result[0] != "success"){
          error("No session data found.");
        }else{
          console.log(result);
          res(result[1]);
        }
      })

    });
  
  };

  async sessionListRequest() {
    return new Promise(async (res, error) => {
      socket.emit('LoadSessions', result => {
        
        if(result[0] != "success"){
          error("No session data found.");
        }else{
          console.log(result);
          res(result[1]);
        }
      })

    });
  
  };

  async removeSessionObserver(sessionID){
    return new Promise(async (res, error) => {
      socket.emit('removeSessionObserver',sessionID, result => {
        
        if(result[0] != "success"){
          error("No session data found.");
        }else{
          console.log(result);
          res(result);
        }
      })

    });
  }

  async checkPermissions(){
   return new Promise(async (res, error) => {

      socket.emit('checkPermissions', result => {
        console.log('checking permissions');
        if(result[0] != "success"){
          error("error no permissions found");
          res(result)
        }else{
          console.log(result);
          res(result);
        }
      })

  });
}

  openSessionListener(){
    console.log('listening for user answers');
    socket.on("newSessionMessage", (result) => {
      console.log('Session recieved a new message');
      eventBus.emit('newSessionMessage', result);
    });  
   
    
    
  }

  openQuestionListener(){
    console.log('listening for coach questions');
    socket.on("ActiveCoachQuestion", (result) => {
      console.log("Active Coach question");
      console.log( result);
      eventBus.emit('ActiveCoachIncoming' , result[2]);
    });    
/*
    console.log('listening for takeover ended calls');
    socket.on("TakeOverEnded", (result) => {
      console.log("take over finished resume or end");
      console.log( result);
      eventBus.emit('TakeOverFinished' , result);
    });   
    */
  }

  openTakeoverStatusListener(){
    
    console.log('listening for takeover ended calls');
    socket.on("TakeOverEnded", (result) => {
      console.log("take over finished resume or end");
      console.log( result);
      eventBus.emit('TakeOverFinished' , result);
    });
  }

  
  sessionTakeOverRequest (sessionID) {
    socket.emit('SessionTakeOverReq', sessionID, result => {

      socket.on("SessionTakenOver", () => {
        console.log('Session Taken Over ') 
        eventBus.emit('TakeOverActivated' , true);
      });    

    })
  };
  

  sendCoachMessage (sessionID,message) {
    console.log("sending coach message:" + message);
    socket.emit('ActiveCoachSend', sessionID, message, result => {
      console.log(result);

    })
  };

  async getUserDetails(){
    return new Promise(async (res, error) => {
      console.log('retrieving user details');
      socket.emit('UserDetails', result => {
        res(result);
      })
    });
  }

  async endTakeOver(takeoverID, carryOnOrFinish){
    return new Promise(async (res, error) => {
 
       socket.emit('EndTakeOver', takeoverID, carryOnOrFinish, result => {
         if(result[0] != "success"){
           error("Take Over failed to finish");
           res(result)
         }else{
          
           console.log(result);
           res(result);
         }
       })
 
   });
 }
  

 



// Comments kept in incase future refinements decide to use Socket.io to communicate with the back end the following code saw errors in failing to return values back to the front end it failed to wait before passing the return value.
/*

  loginUser = async (email, pass) => {
    var loginbool = false;
    await socket.emit('loginUser', { email: email, password: pass }, (response) => {
      loginbool = response.status;
      console.log('received: ' + response.status);
    });

    await socket.on('loginUserReturn', result => {
      loginbool = result;
    });
    return loginbool;
    // Let the server process data from client 
   // const result = await asyncEmit("loginUser", { email: email, password: pass }); 
    // and have it returned back, something like a Fetch request
    //console.log(result);
 }
 
 //(email, pass){

    return new Promise(function(resolve) {
      //var loginSuccess = false;
      socket.emit('loginUser', { email: email, password: pass }, (response) => {
        //loginSuccess = response.status;
        console.log('received: ' + response.status);
      });
      

    });*/
    


}
/*
socket.on("loginCodeUpdate", (arg) => {
  console.log('newCode: ' + arg) 
 });*/


/*
function asyncEmit(eventName) {//, data
  return new Promise(function (resolve, reject) {
    socket.emit(eventName, {email: "test", password: "test" }, (response) => {
      console.log(response);
      resolve(response);
    });

    setTimeout(reject, 1000);
  });
}

*/
    /*socket.on(eventName, result => {
      socket.off(eventName);
      resolve(result);
    });*/

export default new SocketioService();
