<script>

import PostService from "../../services/PostService";
import eventBus from '../../event-bus.js';
import { blockStatement, booleanLiteral, booleanLiteralTypeAnnotation } from "@babel/types";
//import SocketioService from '../../services/socketio.service.js';
import Question from '../Dashboards/Question.vue';

  export default {
    name: "QuestionEditorView",
    components: {
      Question
    },
    props: {
      questionEditorActive: Boolean,
    },
    watch: {
    // It listens to the change in prop name
    questionEditorActive: function () {
      console.log('Active question editor changed');
    },
  },
    data () {
      return {
        questionData:[],
        createModal: null,
        createModalBD: null,
        createTitle: "",
        createDesc: "",
        activeQ: 0,
        questionActive: false,
      }
    },
    async created() {
/*
      var databaseString = await PostService.loadVueFlow(null);
    
      console.log(databaseString.data.body);
      this.questionData = databaseString.data.body;
      console.log(this.questionData[0].Title);
*/
      console.log('question editor created');

      await this.loadQuestions();
      
      eventBus.on('editQuestion', (id) => {

        console.log(this.questionData);
        const result = this.questionData.find((element, index) => { 
         
          if(element.id == id){

            this.activeQ = index;
            this.openModal();
          }
        } )
      });

    },
    mounted(){
      console.log('question editor mounted');

      this.createModal = document.getElementById('question-Modal');
      this.createModalBD = document.getElementById('modal-backdrop');
        
    },
    methods: {
      
      onClickButton (event, id) {
        //this.$emit('OpenFlow', this.questionData[id])
        //eventBus.emit('LoadFlowQuestion',  this.questionData[id]);
        //this.$emit('closeLoader');
        this.activeQ = id;
        this.openModal();

      },
      async openModal(){

        console.log("Qustion Short: " + this.questionData[this.activeQ].questionshort);
        this.createModalBD = document.getElementById('modal-backdrop');
        this.createModal = document.getElementById('question-Modal');
        this.createModal.style.display = 'block';
        this.createModal.style.opacity = '1';
        this.createModalBD.style.display = 'block';
        this.createModalBD.style.opacity = '0.5';
        this.questionActive = true;
        await this.$nextTick();
  
      },
      async closeModal(){
        this.createModalBD = document.getElementById('modal-backdrop');
        this.createModal = document.getElementById('question-Modal');
        this.createModal.style.display = 'none';
        this.createModal.style.opacity = '0';
        this.createModalBD.style.display = 'none';
        this.createModalBD.style.opacity = '0';
        this.questionActive = false;
        await this.loadQuestions();
        await this.$nextTick();
        
      },
      /*async CreateNew(event){
        if (this.createTitle != ""){
          var saveData = ""
          var response = await PostService.saveVueFlow(null, this.createTitle, null, this.createDesc, saveData, true);
          console.log("create response" );//.data.newID
          console.log( response);
          console.log("create id: " + response.data.newID);

          await this.loadQuestions();

          //TODO: reload questions 
          eventBus.emit('reloadQuestions',  this.questionData[response.data.newID]);
          this.$emit('closeLoader');

        }else{
          console.log("question title was blank");
          //Throw Error
        }
        this.closeModal();

        this.createDesc = "";
        this.createTitle = "";
      },*/
      async loadQuestions(){
          var databaseString = await PostService.selectAll("questions");//await PostService.loadVueFlow(null);
          console.log('question Bar Loading in');
          console.log(databaseString.data.body);
          this.questionData = databaseString.data.body;
          console.log(this.questionData[0].question);
      },
            //Update Questions with new values
      updateQuestions(value){
        console.log("update question called.")
        console.log("type is in update: " + value.t)
        this.questionData[this.activeQ].question = value.q;
        this.questionData[this.activeQ].questionshort = value.qs;
        this.questionData[this.activeQ].answers = '{"Answers" :' + JSON.stringify(value.a) + '}';
        this.questionData[this.activeQ].session_allocation = value.s;
        this.questionData[this.activeQ].type = value.t;
        const response = PostService.updateQuestion(this.questionData[this.activeQ])
        console.log(response); // returns error if any exist
      },

      
      async newQuestion(value){

        // question type
        // 0 : Hidden no question
        // 1 : Emoji Answers
        // 2 : Slider Answers
        // 3 : Open Ended Answers

        console.log("insert question called.")
        const newQ = JSON.parse('[{"id": "", "questionshort":"", "question": "", "answers":"","type":"3","session_allocation":""}]');
        newQ[0].answers = '{"Answers" :[]}';

        const response = await PostService.insertQuestion(newQ[0]);
        console.log(response); // returns error if any exist
        //console.log("new id: " + response.data.body[0].id);
        
        await this.loadQuestions();
        this.activeQ = (this.questionData.length - 1);
        
        this.openModal();

      },
      closeQuestions(){
        this.$emit('closeQuestionEdit');
      }


      },
  };
</script>
<template>
<div class="questions-panel-bg" @click="closeQuestions()" v-if="questionEditorActive"></div>
<div class="questions-panel" v-if="questionEditorActive">
<div class="questions-contents">
<span class="close-qe-btn" @click="closeQuestions()">Close</span>
<h2>Questions</h2>
<hr/>
  <p>Virtual coach questions</p>
  <div class="container questions-main-contents">

    <div class="row" >
      <div class="col-sm-3 mb-3">
        <div class="card mb-3">
          <div class="card-body">
            <div  class="card-title">Create a new Question</div>
            <span class="create-session-btn" @click="newQuestion()" data-bs-toggle="modal" data-bs-target="#question-Modal"><i class="fa-solid fa-plus"></i></span>
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-3" v-for="(singleQ, index) in questionData" :key="index">
        <div class="card mb-3">
          <div class="card-header card-title">{{singleQ.questionshort}}</div>
          <div class="card-body">
            <h5 class="card-text">{{singleQ.question}}</h5>
            <p class="card-text"></p>
            <a href="#" class="btn btn-primary" @click="onClickButton($event, index)">Edit question</a>
          </div>
        </div>
      </div>
</div>
</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="question-Modal" tabindex="-1" aria-labelledby="question-ModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="question-ModalLabel">Question</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
      </div>
      <div class="modal-body">
        <Question
        v-if="questionActive"
        :question="questionData[activeQ].question" 
        :questionshort="questionData[activeQ].questionshort" 
        :answers="JSON.parse(questionData[activeQ].answers).Answers"
        :allocation="questionData[activeQ].session_allocation"
        :questionID="activeQ"
        :relationship="questionData[activeQ].questionindex"
        :type="questionData[activeQ].type"
        :mode="true"
        @updateQuestion="updateQuestions"
        />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal()">Close</button>
        <!--<button type="button" class="btn btn-primary"  @click="CreateNew($event, index)">Save changes</button>-->
      </div>
    </div>
  </div>
</div>
<div id="modal-backdrop" class="modal-backdrop fade show" style="display: none;" @click="closeModal()"></div>
</template>

<style>


.questions-main-contents{
  overflow-y: auto;
  max-height: calc(80vh - 100px - 40px); /* height of div - height of header section - padding of parent */
}

.questions-panel{
  background-color:#ffffff;
  border-radius:10px;
  border: solid 1px #f2f2f2;
  width:80vw;
  height:80vh;
  position:absolute;
  z-index:1010;
  top:10vh;
  left:10vw;
  
}

.modal-dialog{
      max-width: 60vw !important;
      width: 60vw;
}

.questions-panel .container{
  /*overflow-y: auto;
    max-height: 75%;//parent would need 100% height*/
}

.questions-panel-bg{
  background-color:rgba(220,220,220,0.5);
  width:100vw;
  height:100vh;
  position:absolute;
  z-index:1005;
  top:0;
  left:0;
}

.questions-contents{
  text-align:left;
  padding:20px 30px;
}

.card{
  border: 1px solid rgba(0,0,0,.125);
  height: 100%;
}


.create-session-btn i{
    font-size: 2rem;
    text-align: center;
    width: 100%;
}


.create-session-btn{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-qe-btn{
  position:absolute;
  top:10px;
  right:10px;
}


  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
      outline-width: 0 !important;
      border-bottom: 2px solid black;
      outline: none;
  }
  .add-question-btn{
    width:auto;
  }
  .add-question-row{
    justify-content: center;
  }

  .modal-backdrop{
    display:none;
  }

  
.form-label{
  text-align:left;
  width: 100%;
}

</style>
