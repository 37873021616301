<script setup >
    /* eslint-disable */
import { defineComponent, h, PropType, computed,ref,reactive, onMounted} from 'vue'




const props = defineProps({
  title: String,
  continueButton: String,
  cancelButton: String,
  modalOpen: Boolean,
  sessionID: String,
})



const selectOptions = ref(true);

const emit = defineEmits(['submit'])

const continueButton = () => {
    emit('submit', selectOptions.value, props.sessionID);
    props.modalOpen = false;
}

const closeModal = ()=>{
    props.modalOpen = false;
}



</script>
<template>
  <div class="modal fade" id="title-Modal" tabindex="-1" aria-labelledby="title-ModalLabel" aria-hidden="true" v-if="props.modalOpen">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title-ModalLabel">{{props.title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
            <select class="form-control" v-model="selectOptions">
                <option value="true">Carry on session</option>
                <option value="false">End users session</option>
            </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal()">{{props.cancelButton}}</button>
          <button type="button" class="btn btn-primary"  @click="continueButton()">{{props.continueButton}}</button>
        </div>
      </div>
    </div>
  </div>
  <div id="modal-backdrop" class="modal-backdrop fade show" @click="closeModal()" v-if="props.modalOpen"></div>
  </template>
<style scoped>

#modal-backdrop{
  display: block !important;
  opacity: 0.5;
}
#title-Modal{
  display:block;
  opacity: 1;
}
</style>