<template>
<div class="container">
  <h2 class="panel-title">Questions & Answers</h2>
  <hr/>

  <div class="container">
    <div class="row add-question-row">
      <div @click="addQuestion = !addQuestion" class="add-question-btn vr-btn-primary">Add Question</div>
    </div>
    <div v-if="addQuestion" class="row">
      <Question
          :question="''" 
          :questionshort="''" 
          :answers="[]"
          :allocation="0"
          :questionID="parseFloat(highestIndex) + 1"
          :relationship="String(parseFloat(highestIndex) + 1)"
          :mode="true"
          :type="3"
          @updateQuestion="newQuestion"
          
          />
    </div>
    <div class="row" v-for="(qana, index) in qanaData" :key="index">
       <Question
        :question="qana.question" 
        :questionshort="qana.questionshort" 
        :answers="JSON.parse(qana.answers).Answers"
        :allocation="qana.session_allocation"
        :questionID="index"
        :relationship="qana.questionindex"
        :mode="false"
        :type="qana.type"
        @updateQuestion="updateQuestions"
        
        />
    </div>
  </div>
</div>
</template>
<script>
import Question from './Question.vue';
//import QuestionService from '../../services/Question.service'
import PostService from "../../services/PostService";
  export default {
    name: "QuestionPanel",
    components: {
        Question
    },
    data () {
      return {
        qanaData: [],
        highestIndex: 0,
        addQuestion: false, 
      }
    },
    async created() {

      const response = await PostService.selectAll("questions");
      console.log(response.data.body)
      this.qanaData = response.data.body
      // GET request to get Questions from the database
      // const response = await fetch("http://localhost:3000/questions");
      //this.qanaData = await response.data.JSON.parse()//.json()

      // loop used to find the highest Index for use when adding new questions 
      /*
      for(var data of this.qanaData ){
        if(Number(data.questionindex) > Number(this.highestIndex )){
          this.highestIndex = Number(data.questionindex);
        }
      }
      */
    },
    methods:{
      //Update Questions with new values

      // question type
      // 0 : Hidden no question
      // 1 : Emoji Answers
      // 2 : Slider Answers
      // 3 : Open Ended Answers

      updateQuestions(value){
        console.log("update question called.")
        this.qanaData[value.id].question = value.q;
        this.qanaData[value.id].questionshort = value.qs;
        this.qanaData[value.id].answers = '{"Answers" :' + JSON.stringify(value.a) + '}';
        this.qanaData[value.id].session_allocation = value.s;
        this.qanaData[value.id].type = value.t;
        const response = PostService.updateQuestion(this.qanaData[value.id])
        console.log(response); // returns error if any exist
      },

      
      newQuestion(value){
        console.log("insert question called.")
        const newQ = JSON.parse('[{"questionshort": "' + value.qs + '","question": "' + value.q + '", "answers":"","type": "' + value.t +'","session_allocation":"' + value.allo + '"}]');
        newQ[0].answers = '{"Answers" :' + JSON.stringify(value.a) + '}';

        const response = PostService.insertQuestion(newQ[0])
        console.log(response); // returns error if any exist
        this.$router.go(); //reloads the page
      }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .q-an-a-card .card-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .card-header .panel-title{
    padding-left: -0.75rem !important;
    margin:10 0px !important;
  }

  .q-an-a-card .card-header h5{
    margin:0 !important;
  }

  .q-an-a-title-input{
    border:none;
    background-color:transparent;
    font-size: 1.25rem;
  }

  .q-an-a-card input:focus-visible, .q-an-a-card input:focus {
      outline-width: 0 !important;
      border-bottom: 2px solid black;
      outline: none;
  }
  .add-question-btn{
    width:auto;
  }
  .add-question-row{
    justify-content: center;
  }

</style>
